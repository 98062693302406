import { ThemeColours } from '@keplerco/core';

type DataTypeValue = string | number | Date;

const ChipTypes: readonly string[] = ['complete', 'in progress', 'pending', 'active', 'inactive', 'not started'];

export function isNumber(value: DataTypeValue): boolean {
  const coercedValue = Number(value.toString());
  return !isNaN(parseInt(coercedValue as any)) && typeof coercedValue === 'number';
}

export function isDate(value: DataTypeValue): boolean {
  const checkRgx = /([a-z]+)(\s)([0-9]+)/gi;
  const matches = checkRgx.test(value.toString());

  if (matches) return false;

  try {
    new Date(value).toISOString();
  } catch (error) {
    return false;
  }

  return true;
}

export function isUrl(value: DataTypeValue): boolean {
  if (value.toString().includes(':')) return false;
  
  try {
    new URL(value.toString());
    return true;
  } catch (error) {
    return false;
  }
}

export function isString(value: DataTypeValue): boolean {
  return !isUrl(value) && !isDate(value) && !isNumber(value);
}

export function getDataType(value: DataTypeValue): IDataType {
  if (value === null || typeof value === 'undefined') return 'null';

  if (isNumber(value)) {
    return 'number';
  } else if (isDate(value)) {
    return 'date';
  } else if (isUrl(value)) {
    return 'url';
  }

  if (ChipTypes.includes(value.toString().trim().toLowerCase())) {
    return 'chip';
  }

  return 'string';
}

export function getChipColour(value: string): [ThemeColours, ThemeColours] {
  const lower = value.toString().toLowerCase().trim();

  if (['active', 'complete'].includes(lower)) return ['white', 'apple'];

  if ('not started' === lower) return ['text', 'default'];

  if ('in progress' === lower) return ['white', 'baby-blue'];

  if ('pending' === lower) return ['white', 'orange'];

  if ('inactive' === lower) return ['white', 'berry'];

  return ['text', 'default'];
}
