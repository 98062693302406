import React, { Fragment, useEffect, useState } from 'react';
import { Anchor, Button, CascadingPanel, CascadingPanels, Chip, colourString, KebabMenu, ListItemLayout, ListLayout, useCascadingPanelsConnector } from '@keplerco/core';
import styles from './people-step.module.css';
import classNames from 'classnames';
import { AstronautsIcon } from '../../../../../widgets/forms/skill-assessment/people-and-skills/astronauts.icon';
import { SelectCohortLayout } from './select-cohort/select-cohort.layout';
import { Cohort, CohortType } from './people-step.models';
import { SelectPeopleLayout } from './select-people/select-people.layout';
import { AssessmentPreferencesResponse } from '../../../../../models/overmind/assessment-preferences';
import { useAppActions, useAppState } from '../../../../../overmind';
import { SkillAssessmentAssignee } from '../../../../../enums';
import { PeopleStepSkeleton } from './people-step.skeleton';
import { IPerson } from '../../../../../widgets/forms/skill-assessment/people-and-skills/people-and-skills.models';

enum CascadingFocusPanelIds {
  SelectEntity = 'SelectEntity',
  SelectPeople = 'SelectPeople',
}

enum ErrorMessage {
  NoManagerSelected = 'Please select a manager',
  NoPersonSelected = 'Please select at least one person.',
}

export function PeopleStepLayout({
  assessmentSlug,
  people,
  preferences,
  setPeople,
  completeStep,
  goToPreviousStep,
  setRoleSlug,
}: {
  assessmentSlug: string;
  people: IPerson[];
  preferences: AssessmentPreferencesResponse;
  setPeople: (people: IPerson[]) => void;
  completeStep: () => void;
  goToPreviousStep: () => void;
  setRoleSlug: (roleSlug: string | undefined) => void;
}): JSX.Element {
  const { openPanelIds, next, previous, closeAll } = useCascadingPanelsConnector();

  const { companyVariables } = useAppState();
  const actions = useAppActions();

  const [selectedEntity, setSelectedEntity] = useState<Cohort>();
  const [selectedPeople, setSelectedPeople] = useState<IPerson[]>(people);

  const [error, setError] = useState<ErrorMessage>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!preferences.allowManagerReview && !preferences.allowPeerReview)
      setSelectedPeople(currentState => {
        currentState.forEach(person => (person.manager = false));
        return currentState;
      });
  }, [preferences.allowManagerReview, preferences.allowPeerReview]);

  useEffect(() => {
    if (!!selectedPeople.length) setError(undefined);
  }, [selectedPeople]);

  async function handleNext() {
    if (!selectedPeople.length) return setError(ErrorMessage.NoPersonSelected);

    if (!selectedPeople.some(p => p.manager) && preferences.allowManagerReview) {
      return setError(ErrorMessage.NoManagerSelected);
    }

    setIsLoading(true);
    await actions.saveSkillAssessmentAssignees({
      companySlug: companyVariables.slug!,
      assessmentAssignee: {
        assessmentGroup: SkillAssessmentAssignee.Learner,
        assessmentSlug: assessmentSlug,
        includeLearnersWithNoDepartment: false,
        assignedTeamChampionId: selectedPeople.find(p => p.manager)?.id,
        userIds: selectedPeople.map(person => person.id),
      },
    });
    setPeople(selectedPeople);
    setIsLoading(false);
    completeStep();
  }

  if (isLoading) return <PeopleStepSkeleton />;

  return (
    <Fragment>
      <div className={classNames('card', styles.container)}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <h5 className="heading5">People</h5>
          <p className="body" style={{ color: colourString('text_1') }}>
            Select the people you would like to be in the assessment.
          </p>
        </div>

        <div className="card action-card">
          <AstronautsIcon />

          <div className="action-card-content">
            <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
              <h3 className="subtitle">Assign people</h3>
              {!!selectedPeople.length && <Chip label={`${selectedPeople.length} people`} variant="tiny" borderColour="g" backgroundColour="g" colour="black" />}
            </div>

            <p className="body2">Assign departments, teams, or individual people and match them with relevant skills for assessment.</p>
          </div>

          <Button type="button" onClick={() => next(CascadingFocusPanelIds.SelectEntity)}>
            Assign
          </Button>
        </div>

        {!!selectedPeople.length && (
          <Fragment>
            <div className="heading5">Assigned people</div>

            <div style={{ height: 400, overflowY: 'auto' }}>
              <ListLayout>
                {selectedPeople?.map((person, index) => (
                  <ListItemLayout key={index}>
                    <div className={classNames('card', styles.content)} style={{ gridTemplateColumns: `auto 1fr 1fr ${person.manager ? 'auto' : '100px'}` }}>
                      <KebabMenu
                        items={[
                          {
                            label: 'Remove person',
                            onClick: () => setSelectedPeople(selectedPeople.filter(p => p.id !== person.id)),
                          },
                        ]}
                      />

                      <div className="caption1" style={{ color: 'var(--accent-2)' }}>
                        {person.firstName} {person.lastName}
                      </div>
                      <div className="caption2">{person.email}</div>

                      {person.manager && (
                        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 15 }}>
                          <Chip label="Manager" backgroundColour={'baby-blue'} />
                        </div>
                      )}
                    </div>
                  </ListItemLayout>
                ))}
              </ListLayout>
            </div>
          </Fragment>
        )}

        {!!error && <div className={classNames('formErrorMessage')}>{error}</div>}
      </div>

      <footer className="card" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 30, background: 'var(--background)' }}>
        <Anchor arrow reverse onClick={goToPreviousStep}>
          Back
        </Anchor>

        <Anchor arrow onClick={handleNext}>
          Next
        </Anchor>
      </footer>

      <CascadingPanels openPanelIds={openPanelIds} onClosePanel={previous}>
        <CascadingPanel id={CascadingFocusPanelIds.SelectEntity}>
          <SelectCohortLayout
            selectedCohort={selectedEntity}
            setSelectedCohort={setSelectedEntity}
            onBack={previous}
            onNext={() => {
              next(CascadingFocusPanelIds.SelectPeople);
            }}
          />
        </CascadingPanel>

        <CascadingPanel id={CascadingFocusPanelIds.SelectPeople}>
          <SelectPeopleLayout
            selectedPeople={selectedPeople}
            showManagerToggle={preferences.allowManagerReview || preferences.allowPeerReview || false}
            selectedCohort={selectedEntity}
            setSelectedPeople={(people: IPerson[]) => setSelectedPeople(people)}
            onBack={previous}
            onDone={() => {
              setRoleSlug(selectedEntity?.type === CohortType.Role ? selectedEntity.slug : undefined);
              closeAll();
            }}
          />
        </CascadingPanel>
      </CascadingPanels>
    </Fragment>
  );
}

