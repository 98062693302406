import { DepartmentListItem, TeamListItem } from '../../../../../models';
import { CompanyEntityListItemResponse } from '../../../../../models/overmind/company-entity';
import { IPerson } from '../../../../../widgets/forms/skill-assessment/people-and-skills/people-and-skills.models';

export interface IPeopleResponse {
  people: IPerson[];
  totalPages: number;
}

export interface ISelectCohortLayoutProps {
  selectedCohort: Cohort | undefined;
  setSelectedCohort: (entity: Cohort) => void;
  onBack: () => void;
  onNext: () => void;
}

export interface ISelectPeopleLayoutProps {
  selectedCohort: Cohort | undefined;
  showManagerToggle: boolean;
  selectedPeople: IPerson[];
  setSelectedPeople: (people: IPerson[]) => void;
  onBack: () => void;
  onDone: () => void;
}

export enum CohortType {
  Department = 'Department',
  Team = 'Team',
  Role = 'Role',
  People = 'People',
}

export interface Cohorts {
  entities: DepartmentListItem[] | CompanyEntityListItemResponse[] | TeamListItem[];
  totalPages: number;
  totalCount: number;
}

export interface Cohort {
  name: string | undefined;
  slug: string | undefined;
  type: CohortType | undefined;
}
