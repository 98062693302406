import React, { Fragment, PropsWithChildren } from 'react';
import { IDisplayListProps } from './lists.models';
import { DropdownSearch, Pager } from '@keplerco/core';
import { ListChildren } from './list-children';

export function DisplayList({ loading, emptyState, dropdownValue, dropdownItems, dialogContained, actions, paging, children }: PropsWithChildren<IDisplayListProps>): JSX.Element {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
      <div style={{ display: 'flex', flexDirection: 'row', gap: 15 }}>
        <DropdownSearch label="Search" value={dropdownValue} items={dropdownItems} dialogContained={dialogContained} responsive multiple />

        {/* {!!button && (
          <div>
            <Button type="button" theme="dark" square onClick={button.onClick}>
              <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                {button.icon}
                {button.text}
              </div>
            </Button>
          </div>
        )} */}
        {actions}
      </div>

      {!loading && (
        <Fragment>
          {!!paging ? (
            <Fragment>
              <ListChildren emptyState={!!emptyState}>{children}</ListChildren>

              {paging.pageCount > 1 && <Pager {...paging} />}
            </Fragment>
          ) : (
            // TODO: move scroll container into ListChildren to avoid unnecessary bottom space
            <div style={{ maxHeight: 400, padding: 1, paddingRight: 10, overflowY: 'scroll' }}>
              <ListChildren emptyState={!!emptyState}>{children}</ListChildren>
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
}
