import React, { useEffect, useState } from 'react';
import { Assignee, TagAssigneeRequest, TagListItem } from '../../../../models/tag';
import { TagType } from '../../../../enums/tag';
import { useAppActions, useAppState } from '../../../../overmind';
import { FetchType } from '../../../../enums';
import { TagInput } from '../../../../components/inputs/tags/tag-input';
import { SkillAssessmentTagsFooter, SkillAssessmentTagsWrapper } from './tags.styles';
import { useParams } from 'react-router-dom';
import { PagePath } from '../../../../navigation/navigation.enums';
import { BaseSearchRequest } from '../../../../models/overmind/search-request';
import { Anchor } from '@keplerco/core';

interface ISkillAssessmentTagsWidgetProps {
  assessmentSlug: string;
  onComplete?: () => void;
}

export function SkillAssessmentTagsWidget(props: ISkillAssessmentTagsWidgetProps): JSX.Element {
  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const [tags, setTags] = useState<TagListItem[] | undefined>([]);
  const [search, setSearch] = useState(``);
  const params = useParams();

  React.useEffect(() => {
    getTagAssignees();
  }, []);

  async function onClickRemoveTagHandler(tagName: string) {
    const assignees: TagAssigneeRequest[] = [
      {
        entitySlug: props.assessmentSlug,
        tagType: TagType.Assessment,
      },
    ];

    await actions.removeAssignees({ tagName, assignees, companySlug: companyVariables.slug! });
  }

  function getTagAssignees(): Assignee[] {
    return [
      {
        entitySlug: params.slug ?? props.assessmentSlug,
        tagType: TagType.Assessment,
      },
    ];
  }

  async function onClickCreateTagHandler(tagName: string, assignees: Assignee[], source: string) {
    if (source === 'input') {
      tagName = search;
      assignees = [
        {
          entitySlug: params.slug ?? props.assessmentSlug,
          tagType: TagType.Assessment,
        },
      ];
    }

    await actions.addAssignees({ tagName, assignees, companySlug: companyVariables.slug! });
  }

  function onTagChange(tags: TagListItem[]) {
    setTags(tags);
  }

  async function searchUserTags(query: string): Promise<TagListItem[]> {
    setSearch(query);
    const searchTagParams: BaseSearchRequest = {
      page: 1,
      pageSize: 15,
      search: query,
      sortAscending: true,
      sortField: 0,
      companySlug: companyVariables.slug!,
    };
    const paginatedResult = await actions.searchTags(searchTagParams);

    if (paginatedResult && paginatedResult.tags) {
      return paginatedResult.tags;
    }

    return [];
  }

  async function getData() {
    actions.startLoader({ path: PagePath.assessmentManagementAllAssessments, type: FetchType.DialogFetching });

    const result = await actions.getAssigneeTags({ entitySlug: params.slug ?? props.assessmentSlug, tagType: TagType.Assessment, companySlug: companyVariables.slug! });

    setTags(!!result ? result : []);

    actions.stopLoader(PagePath.assessmentManagementAllAssessments);
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <SkillAssessmentTagsWrapper>
      <TagInput onRemoveTag={onClickRemoveTagHandler} getAssignees={getTagAssignees} onCreateTag={onClickCreateTagHandler} initialTags={tags} onTagChange={onTagChange} searchTags={searchUserTags} />

      {typeof props.onComplete === 'function' && (
        <SkillAssessmentTagsFooter>
          <Anchor onClick={props.onComplete}>Done</Anchor>
        </SkillAssessmentTagsFooter>
      )}
    </SkillAssessmentTagsWrapper>
  );
}

