import React, { useEffect, useState } from 'react';
import { useAppActions, useAppState } from '../../../overmind';
import { ActivationStatus, FetchStatus, FetchType } from '../../../enums';
import { UploadTwoToneIcon } from './assets/upload-two-tone.icon';
import { PeopleHeaderMobileActionsRow, PeopleHeaderActionsRow } from './people.cms.styles';
import { IPeopleHeaderActionsCMSLayoutProps } from './people.cms.models';
import { BulkActionsMenu } from './bulk-actions-menu/bulk-actions-menu';
import { ColumnConfiguration } from '../../../models/column-configuration';
import { filterableColumns } from './people.cms.helpers';
import { CMSColumn } from '../../../design/layout.styles';
import { AddTwoToneIcon, Button, Checkbox, clearFilter, DropdownItem, DropdownSelect, DropdownSelectItem, Filters, IconButton, Modal, Searchfield, selectFilter, Tooltip, useMatchScreenWidth } from '@keplerco/core';
import { generateDropdownItemsFromUniqueEntities } from '../../../library/helpers/generate-dropdown-items-from-unique-entities';
import { useNavigate, useParams } from 'react-router-dom';
import { PagePath } from '../../../navigation/navigation.enums';
import { ConfirmationModalLayout } from '../../../widgets/layouts';
import { BulkActionModalPayload } from './bulk-actions-menu/bulk-actions-menu.models';

export function PeopleHeaderActionsCMSLayout(props: IPeopleHeaderActionsCMSLayoutProps): JSX.Element {
  const { departmentSlug, teamSlug } = useParams();

  const { fetchState, companyVariables } = useAppState();
  const actions = useAppActions();
  const navigate = useNavigate();

  const [bulkActionModalState, setBulkActionModalState] = useState<BulkActionModalPayload>({
    selectedPeople: undefined,
    showModal: false,
  });

  const showIconButton = useMatchScreenWidth(1360);

  const [selectedDepartment, setSelectedDepartment] = useState<string>();
  const [departmentItems, setDepartmentItems] = useState<DropdownSelectItem[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<string>();
  const [teamItems, setTeamItems] = useState<DropdownItem[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<string>();
  const [countryItems, setCountryItems] = useState<DropdownItem[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<string>();
  const [statusItems, setStatusItems] = useState<DropdownItem[]>([]);

  useEffect(() => {
    async function generateFilterByColumn(column: ColumnConfiguration) {
      if (!column.key || !column.label || !filterableColumns.includes(column.key)) return;

      switch (column.key) {
        case 'Department': {
          const items = generateDropdownItemsFromUniqueEntities(props.departments, (value: string) => setDepartmentItems(currentState => selectFilter(currentState, value)));
          setDepartmentItems(items);
          break;
        }

        case 'Team': {
          const items = generateDropdownItemsFromUniqueEntities(props.teams, (value: string) => setTeamItems(currentState => selectFilter(currentState, value)));
          setTeamItems(items);
          break;
        }

        case 'Country': {
          const items = generateDropdownItemsFromUniqueEntities(props.countries, (value: string) => setCountryItems(currentState => selectFilter(currentState, value)));
          setCountryItems(items);
          break;
        }

        case 'Status': {
          const items = generateDropdownItemsFromUniqueEntities(
            [
              { slug: ActivationStatus.Active.toString(), name: 'Account active' },
              { slug: ActivationStatus.Archived.toString(), name: 'Archived' },
              { slug: ActivationStatus.Inactive.toString(), name: 'Activation required' },
              { slug: ActivationStatus.NotInvited.toString(), name: 'Invite pending' },
            ],
            (value: string) => setStatusItems(currentState => selectFilter(currentState, value))
          );
          setStatusItems(items);
          break;
        }

        default: {
          break;
        }
      }
    }

    props.columnConfiguration.forEach(generateFilterByColumn);
  }, [props.columnConfiguration, props.departments, props.teams, props.countries]);

  async function onInputHandler(value: string) {
    actions.startLoader({ path: props.path, type: FetchType.Custom });
    props.setRequest({ ...props.request, search: value, page: 1 });
  }

  function clearFilters() {
    const nextRequest = structuredClone(props.request);
    nextRequest.page = 1;
    nextRequest.departmentSlug = departmentSlug;
    nextRequest.teamSlug = teamSlug;
    nextRequest.countryId = undefined;
    nextRequest.activationStatus = undefined;
    props.setRequest(nextRequest);

    setSelectedDepartment(undefined);
    setDepartmentItems(currentState => clearFilter(currentState));
    setSelectedTeam(undefined);
    setTeamItems(currentState => clearFilter(currentState));
    setSelectedCountry(undefined);
    setCountryItems(currentState => clearFilter(currentState));
    setSelectedStatus(undefined);
    setStatusItems(currentState => clearFilter(currentState));
  }

  function applyFilters() {
    const nextRequest = structuredClone(props.request);
    nextRequest.page = 1;
    nextRequest.departmentSlug = selectedDepartment;
    nextRequest.teamSlug = selectedTeam;
    nextRequest.countryId = !selectedCountry ? undefined : parseInt(selectedCountry);
    nextRequest.activationStatus = !selectedStatus ? undefined : parseInt(selectedStatus);

    props.setRequest(nextRequest);
  }

  async function handleBulkArchiveAction(actionType: 'archive' | 'unarchive', selectedPeople: string[]) {
    actions.startLoader({ path: PagePath.administrationPeople, type: FetchType.Sending });

    if (actionType === 'archive') {
      await actions.bulkArchivePeople(selectedPeople);
    } else if (actionType === 'unarchive') {
      await actions.bulkUnarchivePeople(selectedPeople);
    }

    setBulkActionModalState({ selectedPeople: undefined, showModal: false });
    applyFilters();
    actions.stopLoader(PagePath.administrationPeople);
  }

  if (props.isMobile)
    return (
      <React.Fragment>
        <PeopleHeaderActionsRow>
          <CMSColumn>
            <Filters onClickClear={clearFilters} onClickApply={applyFilters}>
              {!departmentSlug && !teamSlug && <DropdownSelect label="Department" items={departmentItems} value={selectedDepartment} onChange={event => setSelectedDepartment(event.target.value)} />}

              {!teamSlug && <DropdownSelect label="Team" items={teamItems} value={selectedTeam} onChange={event => setSelectedTeam(event.target.value)} />}

              <DropdownSelect label="Country" items={countryItems} value={selectedCountry} onChange={event => setSelectedCountry(event.target.value)} />

              <DropdownSelect label="Status" items={statusItems} value={selectedStatus} onChange={event => setSelectedStatus(event.target.value)} />
            </Filters>
          </CMSColumn>

          <CMSColumn>
            <Tooltip content="Bulk Upload">
              <IconButton iconType="fill" borderColour="borders" backgroundColour="cards" borderRadius="10px" borderWidth={1} onClick={() => navigate(`${PagePath.administrationBase}${PagePath.administrationPeopleBulkUpload.replace(':companySlug', companyVariables.slug!)}`)}>
                <UploadTwoToneIcon />
              </IconButton>
            </Tooltip>
          </CMSColumn>

          <CMSColumn>
            <Tooltip content="Add A Person">
              <IconButton iconType="fill" borderColour="borders" backgroundColour="cards" borderRadius="10px" borderWidth={1} onClick={() => props.setPersonToManage({ show: true })}>
                <AddTwoToneIcon />
              </IconButton>
            </Tooltip>
          </CMSColumn>

          <CMSColumn style={{ width: '100%' }}>
            <Searchfield loading={fetchState[props.path].status === FetchStatus.Active && fetchState[props.path].type === FetchType.Custom} responsive onInput={onInputHandler} />
          </CMSColumn>
        </PeopleHeaderActionsRow>

        <PeopleHeaderMobileActionsRow>
          <CMSColumn>
            <Checkbox
              checked={props.selectedRows.length === props.data?.employees.length}
              id="selectAll"
              onChange={() => {
                if (!props.data?.employees) return;
                const ids: any[] = props.data.employees.map(person => ({ id: person.id! }));
                props.setSelectedRows(props.selectedRows.length === props.data?.employees.length ? [] : ids);
              }}
            >
              Select all
            </Checkbox>
          </CMSColumn>
          {props.selectedRows.length > 0 && (
            <CMSColumn style={{ textAlign: 'right' }}>
              <BulkActionsMenu
                handleBulkActionModalState={setBulkActionModalState}
                path={props.path}
                selectedRows={props.selectedRows}
                setSelectedRows={props.setSelectedRows}
                data={props.data}
                onClickSendActivationInvitation={props.onClickSendActivationInvitation}
                onClickSendActivationReminder={props.onClickSendActivationReminder}
              />
            </CMSColumn>
          )}
        </PeopleHeaderMobileActionsRow>
      </React.Fragment>
    );

  return (
    <React.Fragment>
      <PeopleHeaderActionsRow>
        <CMSColumn>
          <Searchfield loading={fetchState[props.path].status === FetchStatus.Active && fetchState[props.path].type === FetchType.Custom} onInput={onInputHandler} />
        </CMSColumn>

        <CMSColumn>
          <Filters onClickClear={clearFilters} onClickApply={applyFilters}>
            {!departmentSlug && !teamSlug && <DropdownSelect label="Department" items={departmentItems} value={selectedDepartment} onChange={event => setSelectedDepartment(event.target.value)} />}

            {!teamSlug && <DropdownSelect label="Team" items={teamItems} value={selectedTeam} onChange={event => setSelectedTeam(event.target.value)} />}

            <DropdownSelect label="Country" items={countryItems} value={selectedCountry} onChange={event => setSelectedCountry(event.target.value)} />

            <DropdownSelect label="Status" items={statusItems} value={selectedStatus} onChange={event => setSelectedStatus(event.target.value)} />
          </Filters>
        </CMSColumn>

        <CMSColumn>
          {showIconButton ? (
            <Tooltip content="Bulk Upload">
              <IconButton iconType="fill" borderColour="borders" backgroundColour="cards" borderRadius="10px" borderWidth={1} onClick={() => navigate(`${PagePath.administrationBase}${PagePath.administrationPeopleBulkUpload.replace(':companySlug', companyVariables.slug!)}`)}>
                <UploadTwoToneIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <>
              <Button type="button" square theme="dark" onClick={() => navigate(`${PagePath.administrationBase}${PagePath.administrationPeopleBulkUpload.replace(':companySlug', companyVariables.slug!)}`)}>
                <div style={{ display: 'flex', gap: 15, alignItems: 'center' }}>
                  <UploadTwoToneIcon />
                  Bulk Upload
                </div>
              </Button>
            </>
          )}
        </CMSColumn>

        <CMSColumn>
          {showIconButton ? (
            <Tooltip content="Add A Person">
              <IconButton iconType="fill" borderColour="borders" backgroundColour="cards" borderRadius="10px" borderWidth={1} onClick={() => props.setPersonToManage({ show: true })}>
                <AddTwoToneIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Button type="button" square theme="dark" onClick={() => props.setPersonToManage({ show: true })}>
              <div style={{ display: 'flex', gap: 15, alignItems: 'center' }}>
                <AddTwoToneIcon />
                Add A Person
              </div>
            </Button>
          )}
        </CMSColumn>

        <CMSColumn>
          <BulkActionsMenu
            handleBulkActionModalState={setBulkActionModalState}
            path={props.path}
            selectedRows={props.selectedRows}
            setSelectedRows={props.setSelectedRows}
            data={props.data}
            onClickSendActivationInvitation={props.onClickSendActivationInvitation}
            onClickSendActivationReminder={props.onClickSendActivationReminder}
          />
        </CMSColumn>
      </PeopleHeaderActionsRow>

      <Modal open={bulkActionModalState.showModal} onClose={() => setBulkActionModalState({ selectedPeople: undefined, showModal: false })}>
        <ConfirmationModalLayout
          textAlign="left"
          titleSize="standard"
          onClickCancel={() => setBulkActionModalState({ selectedPeople: undefined, showModal: false })}
          onClickSubmit={() => {
            const { actionType, selectedPeople } = bulkActionModalState;
            if (selectedPeople && (actionType === 'archive' || actionType === 'unarchive')) {
              handleBulkArchiveAction(actionType, selectedPeople);
            }
          }}
          title={bulkActionModalState.actionType === 'archive' ? 'Archive users' : 'Unarchive users'}
          subtitle={`Are you sure you want to ${bulkActionModalState.actionType === 'archive' ? 'archive' : 'unarchive'} ${bulkActionModalState.selectedPeople && bulkActionModalState.selectedPeople?.length > 0 && bulkActionModalState?.selectedPeople.length} people?`}
          submitButtonText={`Yes, ${bulkActionModalState.actionType === 'archive' ? 'archive' : 'unarchive'}`}
          cancelButtonText="No, cancel"
        />
      </Modal>
    </React.Fragment>
  );
}
