import { IconProps } from '@keplerco/core';
import React from 'react';

export function CircleCheckIcon({ size = 20}: IconProps): JSX.Element {
  return (
    <svg className="kplr_checkIcon" width={size} height={size} viewBox="0 0 20 20">
      <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="var(--link-text)" fill="none" />
      <path
        d="M7.91209 12.9006L8.2858 13.3211L8.6595 12.9006L14.3276 6.52398C14.3539 6.4945 14.399 6.49184 14.4285 6.51805C14.458 6.54426 14.4606 6.5894 14.4344 6.61889L14.7944 6.93887L14.4344 6.61889L8.33919 13.476C8.32563 13.4913 8.3062 13.5 8.2858 13.5C8.2654 13.5 8.24597 13.4913 8.23241 13.476L7.85871 13.8082L8.23241 13.476L5.1848 10.0475C5.15859 10.018 5.16124 9.97283 5.19073 9.94662C5.22021 9.92041 5.26536 9.92306 5.29157 9.95255L7.91209 12.9006Z"
        stroke="var(--link-text)"
        strokeLinecap="round"
        fill="none"
      />
    </svg>
  );
}

export default CircleCheckIcon;
