import { AddTwoToneIcon, Button, DropdownItem, DropdownSelect, KebabMenu, Panel } from '@keplerco/core';
import React, { Fragment, useEffect, useState } from 'react';
import { CompanyEntityListItemResponse } from '../../../../models/overmind/company-entity';
import { useAppActions, useAppState } from '../../../../overmind';
import { IManageSkillsWidgetProps } from './manage-role.models';
import { PagePath } from '../../../../navigation/navigation.enums';
import { extractHighestOrganizationLevel } from '../../../../library/helpers/permissions/extract-highest-organization-level';
import { CompanyEntitySearchParams } from '../../../../models/overmind/search-params';
import { EntityType, OrganizationLevelType, SortField } from '../../../../enums';
import { ExplainerFocusPanelLayout } from '../../../../widgets/layouts';
import { SkeletonLoaderColumn, SkeletonLoaderRow } from '../../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader.styles';
import { SkeletonLoader } from '../../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader';
import { useParams } from 'react-router-dom';
import { DisplayList } from '../../../../components/lists/display-list';
import { ListItem } from '../../../../components/lists/list-item';
import { createLevelsArray } from '../../../../library/helpers/create-levels-array';

export function ManageSkillsWidget({ path, loadingSkills, setLoadingSkills, setSkillsToAssign, setSkillsToUnassign, onClickImportSkill, refetchDropdownItems }: IManageSkillsWidgetProps): JSX.Element {
  const { roleSlug } = useParams();

  const actions = useAppActions();
  const { companyVariables, permissions } = useAppState();
  const organizationLevel = extractHighestOrganizationLevel(permissions?.roleManagement?.organizationLevels);
  const defaultRequest: CompanyEntitySearchParams = {
    search: undefined,
    sortAscending: true,
    sortField: SortField.Name,
    pageSize: 99999, // ridiculously high pageSize to fetch ALL skills
    page: 1,
    organizationLevel: organizationLevel?.organizationLevel ?? OrganizationLevelType.Learner,
    companySlug: companyVariables.slug,
    departmentSlug: undefined,
    teamSlug: undefined,
    learnerSlug: undefined,
    searchGlobally: false,
  };

  const [selectedDropdownItems, setSelectedDropdownItems] = useState<string[]>([]);
  const [dropdownItems, setDropdownItems] = useState<DropdownItem[]>([]);
  const [skills, setSkills] = useState<CompanyEntityListItemResponse[]>([]);
  const [skillToView, setSkillToView] = useState<CompanyEntityListItemResponse>();

  function onClickDropdownItem(skill: CompanyEntityListItemResponse) {
    setSelectedDropdownItems(currentState => {
      const nextState = structuredClone(currentState);
      const index = nextState.indexOf(skill.slug);
      if (index === -1) {
        nextState.push(skill.slug);
        addSkill(skill);
      } else {
        nextState.splice(index, 1);
        removeSkill(skill);
      }
      return nextState;
    });
  }

  async function getDropdownItems(skills: CompanyEntityListItemResponse[]) {
    const response = await actions.getCompanySkills(defaultRequest);

    const selectedItems = skills.map(skill => skill.slug!);
    setSelectedDropdownItems(selectedItems);

    const items: DropdownItem[] =
      response?.entities.map(entity => ({
        value: entity.slug,
        label: entity.name,
        onClick: () => onClickDropdownItem(entity),
      })) ?? [];
    skills.forEach(skill => {
      if (!items.some(item => item.value === skill.slug)) {
        items.push({
          value: skill.slug,
          label: skill.name,
          onClick: () => onClickDropdownItem(skill),
        });
      }
    });
    setDropdownItems(items);
  }

  useEffect(() => {
    async function getSkills(): Promise<CompanyEntityListItemResponse[]> {
      const request: CompanyEntitySearchParams = {
        ...defaultRequest,
        relatedSlug: roleSlug,
        relatedType: EntityType.Role,
        searchGlobally: path === PagePath.roleManagementImportRole,
      };
      const skills = await actions.getCompanySkills(request);
      setSkills(skills?.entities ?? []);
      if (path === PagePath.roleManagementImportRole && !!skills?.entities.length) setSkillsToAssign(skills?.entities);
      return skills?.entities ?? [];
    }

    async function getData() {
      setLoadingSkills(true);

      if (!roleSlug) {
        await getDropdownItems([]);
        setLoadingSkills(false);
        return;
      }

      const response = await getSkills();
      await getDropdownItems(response);
      setLoadingSkills(false);
    }

    getData();
  }, [roleSlug]);

  useEffect(() => {
    async function getData() {
      setLoadingSkills(true);
      await getDropdownItems(skills);
      setLoadingSkills(false);
    }

    if (!!refetchDropdownItems) getData();
  }, [refetchDropdownItems]);

  async function addSkill(skill: CompanyEntityListItemResponse) {
    setSkills(currentState => (!currentState.some(temp => temp.slug === skill.slug) ? [...currentState, skill] : currentState));
    setSkillsToAssign(currentState => [...currentState, skill]);
    setSkillsToUnassign(currentState => currentState.filter(temp => temp.slug !== skill.slug));
  }

  async function removeSkill(skill: CompanyEntityListItemResponse): Promise<CompanyEntityListItemResponse[]> {
    let nextSkills: CompanyEntityListItemResponse[] = [];
    setSkills(currentState => {
      nextSkills = currentState.filter(temp => temp.slug !== skill.slug);
      return nextSkills;
    });
    setSkillsToAssign(currentState => currentState.filter(temp => temp.slug !== skill.slug));
    if (path !== PagePath.roleManagementImportRole) setSkillsToUnassign(currentState => [...currentState, skill]);
    return nextSkills;
  }

  if (loadingSkills) {
    return (
      <div>
        <SkeletonLoaderRow style={{ marginBottom: 15 }}>
          <SkeletonLoaderColumn>
            <SkeletonLoader height="43px" />
          </SkeletonLoaderColumn>
        </SkeletonLoaderRow>

        <SkeletonLoaderRow>
          <SkeletonLoaderColumn>
            <SkeletonLoader height="400px" />
          </SkeletonLoaderColumn>
        </SkeletonLoaderRow>
      </div>
    );
  }

  return (
    <Fragment>
      <DisplayList
        loading={loadingSkills}
        dropdownValue={selectedDropdownItems}
        dropdownItems={dropdownItems}
        actions={
          !!onClickImportSkill ? (
            <div>
              <Button type="button" theme="dark" square onClick={onClickImportSkill}>
                <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                  <AddTwoToneIcon />
                  Import skill
                </div>
              </Button>
            </div>
          ) : undefined
        }
      >
        {skills.map(skill => (
          <ListItem key={skill.slug}>
            <div style={{ display: 'grid', gap: 15, alignItems: 'center', gridTemplateColumns: 'auto 1fr 195px' }}>
              <KebabMenu
                items={[
                  {
                    label: 'Remove skill',
                    onClick: async () => {
                      const response = await removeSkill(skill);
                      setDropdownItems(currentState => currentState.map(item => ({ ...item, selected: response.some(skill => skill.slug === item.value) })));
                    },
                  },
                  {
                    label: 'View skill',
                    onClick: async () => setSkillToView(skill),
                  },
                ]}
              />

              <div>
                <div className="caption1" style={{ color: 'var(--accent-2)' }}>
                  {skill.name}
                </div>

                <div className="caption2">{skill.description ?? 'No description provided'}</div>
              </div>

              <DropdownSelect
                label="Expected proficiency"
                value={skill.level?.toString()}
                items={createLevelsArray(companyVariables.maxLevel, companyVariables.minLevel).map(level => ({
                  label: `Level ${level}`,
                  value: level.toString(),
                  onClick: async () => {
                    setSkillsToAssign(currentState => {
                      const nextState: CompanyEntityListItemResponse[] = structuredClone(currentState);
                      const nextSkill = nextState.find(temp => temp.slug === skill.slug);
                      !!nextSkill ? (nextSkill.level = level) : nextState.push(structuredClone({ ...skill, level }));
                      return nextState;
                    });

                    setSkills(currentState => {
                      const nextState: CompanyEntityListItemResponse[] = currentState.map(temp => ({ ...temp }));
                      const nextItem = nextState.find(temp => temp.slug === skill.slug);
                      if (!!nextItem) nextItem.level = level;
                      return nextState;
                    });
                  },
                  selected: skill.level === level,
                }))}
                responsive
              />
            </div>
          </ListItem>
        ))}
      </DisplayList>

      <Panel open={!!skillToView} onClose={() => setSkillToView(undefined)}>
        {!!skillToView && <ExplainerFocusPanelLayout supertitle="Role Management" title={skillToView.name} description={skillToView.description ?? ''} onClose={() => setSkillToView(undefined)} />}
      </Panel>
    </Fragment>
  );
}
