import React from 'react';
import styles from './entity-skill-scores-card.module.css';
import { SkillLevel, SkillPercentage, scorePercentageRAGColour, scoreLevelRAGColour, GridLayout, GridItemLayout } from '@keplerco/core';
import { IEntitySkillScoresCardProps } from './entity-skill-scores-card.models';
import classNames from 'classnames';
import { UrgencyIndicationChip } from '../../../pages/your-skills/urgency-indication.chip';
import { useAppState } from '../../../overmind';
import { PagePath } from '../../../navigation/navigation.enums';
import { comparisonScoreTitle } from '../../../library/helpers/comparison-score-title';
import { ScoreComparison } from '../../../enums/score-comparison';

export function EntitySkillScoresCard({ overallScore, path, technicalScore, behaviouralScore, comparisonScore, name, urgencyIndication }: IEntitySkillScoresCardProps) {
  const { companyVariables } = useAppState();

  return (
    <div className={classNames(styles.cardWrapper, 'kplr_entitySkillLevelCard')}>
      <h5 className={classNames(styles.cardTitle, 'heading5')}>{name}</h5>
      <div className={styles.cardContent}>
        {!!urgencyIndication ? (
          <UrgencyIndicationChip urgencyIndication={urgencyIndication} />
        ) : (
          <GridLayout columnCount={2} gap={0}>
            <GridItemLayout>
              <div className={styles.skillScore}>
                {companyVariables.useLevels ? (
                  <SkillLevel
                    level={overallScore?.level ?? companyVariables.minLevel}
                    minLevel={companyVariables.minLevel}
                    maxLevel={companyVariables.maxLevel}
                    noLevel={!overallScore}
                    dotColour={path !== PagePath.yourSkillsBase ? scoreLevelRAGColour(overallScore?.level ?? companyVariables.minLevel, companyVariables.minLevel, companyVariables.maxLevel) : 'baby-blue'}
                    title={path === PagePath.yourSkillsBase ? 'Your proficiency' : 'Overall skill average'}
                  />
                ) : (
                  <SkillPercentage
                    percentage={overallScore?.percentage ?? 0}
                    noPercentage={!overallScore}
                    barColour={path !== PagePath.yourSkillsBase ? scorePercentageRAGColour(overallScore?.percentage ?? 0) : 'baby-blue'}
                    title={path === PagePath.yourSkillsBase ? 'Your proficiency' : 'Overall skill average'}
                  />
                )}
              </div>

              {path !== PagePath.yourSkillsBase && (
                <div className={styles.skillScore} style={{ marginTop: 10 }}>
                  {companyVariables.useLevels ? (
                    <SkillLevel
                      level={technicalScore?.level ?? companyVariables.minLevel}
                      minLevel={companyVariables.minLevel}
                      maxLevel={companyVariables.maxLevel}
                      noLevel={!technicalScore}
                      dotColour={scoreLevelRAGColour(technicalScore?.level ?? companyVariables.minLevel, companyVariables.minLevel, companyVariables.maxLevel)}
                      title="Role-based skill average"
                    />
                  ) : (
                    <SkillPercentage
                      percentage={technicalScore?.percentage ?? 0}
                      noPercentage={!technicalScore}
                      barColour={scorePercentageRAGColour(technicalScore?.percentage ?? 0)}
                      title="Role-based skill average"
                    />
                  )}
                </div>
              )}
            </GridItemLayout>

            <GridItemLayout>
              {companyVariables.scoreComparison !== ScoreComparison.None && (
                <div className={styles.skillScore}>
                  {companyVariables.useLevels ? (
                    <SkillLevel
                      level={comparisonScore?.level ?? companyVariables.minLevel}
                      minLevel={companyVariables.minLevel}
                      maxLevel={companyVariables.maxLevel}
                      noLevel={!comparisonScore}
                      noLevelText="Not captured"
                      dotColour={path !== PagePath.yourSkillsBase ? scoreLevelRAGColour(comparisonScore?.level ?? companyVariables.minLevel, companyVariables.minLevel, companyVariables.maxLevel) : 'baby-blue'}
                      title={comparisonScoreTitle(companyVariables.scoreComparison)}
                    />
                  ) : (
                    <SkillPercentage
                      percentage={comparisonScore?.percentage ?? 0}
                      noPercentage={!comparisonScore}
                      noPercentageText="Not captured"
                      barColour={path !== PagePath.yourSkillsBase ? scorePercentageRAGColour(comparisonScore?.percentage ?? 0) : 'baby-blue'}
                      title={comparisonScoreTitle(companyVariables.scoreComparison)}
                    />
                  )}
                </div>
              )}

              {path !== PagePath.yourSkillsBase && (
                <div className={styles.skillScore} style={{ marginTop: 10 }}>
                  {companyVariables.useLevels ? (
                    <SkillLevel
                      level={behaviouralScore?.level ?? companyVariables.minLevel}
                      minLevel={companyVariables.minLevel}
                      maxLevel={companyVariables.maxLevel}
                      dotColour={scoreLevelRAGColour(behaviouralScore?.level ?? companyVariables.minLevel, companyVariables.minLevel, companyVariables.maxLevel)}

                      noLevel={!behaviouralScore}
                      title="Behavioural skill average"
                    />
                  ) : (
                    <SkillPercentage
                      percentage={behaviouralScore?.percentage ?? 0}
                      noPercentage={!behaviouralScore}
                      barColour={scorePercentageRAGColour(behaviouralScore?.percentage ?? 0)}
                      title="Behavioural skill average"
                    />
                  )}
                </div>
              )}
            </GridItemLayout>
          </GridLayout>
        )}
      </div>
    </div>
  );
}

