import { Anchor, Button, FocusPanelHeader, GridItemLayout, GridLayout, useMatchScreenWidth } from '@keplerco/core';
import React, { useEffect, useState } from 'react';
import { useAppActions, useAppState } from '../../../../../../overmind';
import { SearchList } from '../../../../../../components/lists/search-list';
import { ListItem } from '../../../../../../components/lists/list-item';
import { OrganizationLevelType, SortField } from '../../../../../../enums';
import classNames from 'classnames';
import styles from './select-cohort.module.css';
import { EmptyState } from '../../../../../../components/general/empty-state/empty-state';
import { Cohorts, CohortType, ISelectCohortLayoutProps } from '../people-step.models';
import { DepartmentIcon } from './department.icon';
import { PeopleIcon } from './people.icon';
import { RoleIcon } from './role.icon';
import { TeamIcon } from './team-icon';
import { CompanyEntitySearchParams } from '../../../../../../models/overmind/search-params';
import { BaseSearchRequest } from '../../../../../../models';

export function SelectCohortLayout({ selectedCohort, setSelectedCohort, onBack, onNext }: ISelectCohortLayoutProps): JSX.Element {
  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const [loading, setLoading] = useState<boolean>(false);

  const [selectedCohortType, setSelectedCohortType] = useState<CohortType>(CohortType.Department);
  const [cohorts, setCohorts] = useState<Cohorts>();
  const [search, setSearch] = useState<string>('');
  const [activePageNumber, setActivePageNumber] = useState<number>(1);

  const isMobile = useMatchScreenWidth('mobile');

  async function getRoles() {
    const searchParams: CompanyEntitySearchParams = {
      search: search,
      page: activePageNumber,
      pageSize: 5,
      sortAscending: true,
      sortField: SortField.Name,
      departmentSlug: undefined,
      learnerSlug: undefined,
      teamSlug: undefined,
      companySlug: companyVariables.slug,
      organizationLevel: OrganizationLevelType.Company,
      searchGlobally: false,
    };
    const response = await actions.getCompanyRoles(searchParams);
    setCohorts({ entities: response?.entities ?? [], totalPages: response?.totalPages ?? 0, totalCount: response?.totalCount ?? 0 });
  }

  async function getDepartments() {
    const departmentRequest: BaseSearchRequest = { search: search, sortAscending: true, sortField: SortField.Name, pageSize: 15, companySlug: companyVariables.slug };
    const response = await actions.searchDepartments(departmentRequest);
    setCohorts({ entities: response?.departments ?? [], totalPages: response?.totalPages ?? 0, totalCount: response?.totalCount ?? 0 });
  }

  async function getTeams() {
    const request: BaseSearchRequest = { search: search, sortAscending: true, sortField: SortField.Name, pageSize: 15, companySlug: companyVariables.slug };
    const response = await actions.searchTeams(request);
    setCohorts({ entities: response?.teams ?? [], totalPages: response?.totalPages ?? 0, totalCount: response?.totalPages ?? 0 });
  }

  useEffect(() => {
    async function getData() {
      setLoading(true);

      switch (selectedCohortType) {
        case CohortType.Department: {
          await getDepartments();
          break;
        }

        case CohortType.Team: {
          await getTeams();
          break;
        }

        case CohortType.Role: {
          await getRoles();
          break;
        }

        default:
          break;
      }
      setLoading(false);
    }

    getData();
  }, [search]);

  return (
    <div className="panelContent">
      <FocusPanelHeader supertitle="Assessments" title="Who do you want to assess?" subtitle="Select departments, teams, role or individual people to take part in the assessment." />

      <div>
        <div className={classNames('card', styles.content)}>
          <GridLayout columnCount={isMobile ? 1 : 4}>
            <GridItemLayout>
              <div
                className={classNames(styles.entity, {
                  [styles.selected]: selectedCohortType === CohortType.Department,
                })}
                onClick={() => {
                  setSelectedCohortType(CohortType.Department);
                  getDepartments();
                }}
              >
                <DepartmentIcon />
                <div>
                  <div className={classNames('heading5')}>Department</div>

                  <div className={classNames('caption2')}>Select specific departments</div>
                </div>
              </div>
            </GridItemLayout>

            <GridItemLayout>
              <div
                className={classNames(styles.entity, {
                  [styles.selected]: selectedCohortType === CohortType.Team,
                })}
                onClick={() => {
                  setSelectedCohortType(CohortType.Team);
                  getTeams();
                }}
              >
                <TeamIcon />
                <div>
                  <div className={classNames('heading5')}>Team</div>

                  <div className={classNames('caption2')}>Choose a team in the company</div>
                </div>
              </div>
            </GridItemLayout>

            <GridItemLayout>
              <div
                className={classNames(styles.entity, {
                  [styles.selected]: selectedCohortType === CohortType.Role,
                })}
                onClick={() => {
                  setSelectedCohortType(CohortType.Role);
                  getRoles();
                }}
              >
                <RoleIcon />
                <div>
                  <div className={classNames('heading5')}>Role</div>

                  <div className={classNames('caption2')}>Choose a role in the company</div>
                </div>
              </div>
            </GridItemLayout>

            <GridItemLayout>
              <div
                className={classNames(styles.entity, {
                  [styles.selected]: selectedCohortType === CohortType.People,
                })}
                onClick={() => {
                  setSelectedCohortType(CohortType.People);
                  setSelectedCohort({ name: undefined, slug: undefined, type: CohortType.People });
                  setCohorts({ entities: [], totalPages: 0, totalCount: 0 });
                  onNext();
                }}
              >
                <PeopleIcon />
                <div>
                  <div className={classNames('heading5')}>People</div>

                  <div className={classNames('caption2')}>Choose employees for assessment</div>
                </div>
              </div>
            </GridItemLayout>
          </GridLayout>
          {loading && !cohorts ? (
            <div className={styles.loadingState}>
              <object data={'/graphics/loading-state.graphic.svg'} type="image/svg+xml">
                <img src={'graphics/loading-state.graphic.svg'} alt="" />
              </object>

              <span className="heading5">Loading...</span>
            </div>
          ) : (
            <>
              {selectedCohortType !== CohortType.People && (
                <SearchList
                  loading={loading}
                  onInput={search => setSearch(search)}
                  paging={{
                    activePageNumber: activePageNumber ?? 1,
                    pageCount: cohorts?.totalPages ?? 0,
                    onPageChange: page => setActivePageNumber(page),
                  }}
                >
                  {!cohorts?.entities.length ? (
                    <EmptyState title="Nothing to show" subtitle="Select department, team, role or prople" />
                  ) : (
                    cohorts?.entities.map(entity => {
                      return (
                        <ListItem
                          key={'slug' in entity ? entity.slug : entity.teamSlug}
                          selected={selectedCohort?.slug === ('slug' in entity ? entity.slug : entity.teamSlug)}
                          onClick={() => setSelectedCohort({ slug: 'slug' in entity ? entity.slug : entity.teamSlug, name: 'name' in entity ? entity.name : entity.teamName, type: selectedCohortType })}
                        >
                          <h5 className="caption1" style={{ color: 'var(--accent-2)' }}>{'name' in entity ? entity.name : entity.teamName}</h5>
                        </ListItem>
                      );
                    })
                  )}
                </SearchList>
              )}
            </>
          )}
        </div>
      </div>

      <footer className="panelFooter">
        <Anchor onClick={onBack}>Back</Anchor>

        <Button type="button" onClick={() => onNext()}>
          Next
        </Button>
      </footer>
    </div>
  );
}
