import React, { Fragment, useState } from 'react';
import { useKeplerNavigate } from '../../../navigation/guards/use-kepler-navigate';
import { useAppActions, useAppState } from '../../../overmind';
import { AssessmentsWizardDetails } from './assessments-wizard-peer.page';
import { AssessmentPreferencesResponse } from '../../../models/overmind/assessment-preferences';
import { Anchor, Button, colourString, PageHeader, SmartSelect, SmartSelectSection, Tooltip } from '@keplerco/core';
import { AssessmentsWizardDetailsWidget } from './assessments-wizard-details.widget';
import { AssessmentType } from '../../../enums/assessment-type';
import { AssessmentsWizardPreferencesWidget } from './preferences/assessments-wizard-preferences.widget';
import { PagePath } from '../../../navigation/navigation.enums';
import { FetchType } from '../../../enums';
import { AssessmentsWizardEntitiesWidget } from './assessments-wizard-entities.widget';
import { AssessmentsWizardFOPWidget } from './assessments-wizard-fop.widget';
import { AssessmentsWizardTagsWidget } from './assessments-wizard-tags.widget';
import { PageLoaderLayer } from '../../../components/general/loading-state/loader-layers/page-loader-layer/page-loader-layer';

enum Steps {
  Details = 'Details',
  Preferences = 'Preferences',
  Entities = 'Entities',
  FieldsOfPractice = 'Fields of Practice',
  Tags = 'Tags',
}

// TODO: onChange decrease index
export function AssessmentsWizardFOPPage(): JSX.Element {
  const keplerNavigate = useKeplerNavigate();

  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [details, setDetails] = useState<AssessmentsWizardDetails>();
  const [preferences, setPreferences] = useState<AssessmentPreferencesResponse>({
    forceAssessment: false,
    minScore: 0,
    maxScore: 0,
  });

  const steps = [
    <SmartSelectSection
      key={Steps.Details}
      headerContent={
        <Fragment>
          <h5 className="heading5">{Steps.Details}</h5>
          <p className="body2" style={{ color: colourString('text_1') }}>
            Name your assessment, and choose how many Kepler Points you would like to award
          </p>
        </Fragment>
      }
    >
      <AssessmentsWizardDetailsWidget
        assessmentType={AssessmentType.FieldsOfPractice}
        onSaveAndContinue={details => {
          setActiveIndex(1);
          setDetails(details);
        }}
      />
    </SmartSelectSection>,
    <SmartSelectSection
      key={Steps.Preferences}
      headerContent={
        <Fragment>
          <h5 className="heading5">{Steps.Preferences}</h5>
          <p className="body2" style={{ color: colourString('text_1') }}>
            Choose your preferences for your assessment
          </p>
        </Fragment>
      }
    >
      <AssessmentsWizardPreferencesWidget assessmentSlug={details?.slug} assessmentType={AssessmentType.FieldsOfPractice} preferences={preferences} setPreferences={setPreferences} onSaveAndContinue={() => setActiveIndex(2)} />
    </SmartSelectSection>,
    <SmartSelectSection
      key={Steps.Entities}
      headerContent={
        <Fragment>
          <h5 className="heading5">{Steps.Entities}</h5>
          <p className="body2" style={{ color: colourString('text_1') }}>
            Select entities for assessment
          </p>
        </Fragment>
      }
    >
      <AssessmentsWizardEntitiesWidget assessmentSlug={details?.slug} onSaveAndContinue={() => setActiveIndex(3)} />
    </SmartSelectSection>,
    <SmartSelectSection
      key={Steps.FieldsOfPractice}
      headerContent={
        <Fragment>
          <h5 className="heading5">{Steps.FieldsOfPractice}</h5>
          <p className="body2" style={{ color: colourString('text_1') }}>
            Select the fields of practice for the assessment
          </p>
        </Fragment>
      }
    >
      <AssessmentsWizardFOPWidget details={details} onSaveAndContinue={() => setActiveIndex(4)} />
    </SmartSelectSection>,
    <SmartSelectSection
      key={Steps.Tags}
      headerContent={
        <Fragment>
          <h5 className="heading5">{Steps.Tags}</h5>
          <p className="body2" style={{ color: colourString('text_1') }}>
            Assign tags to an assessment for analytics
          </p>
        </Fragment>
      }
    >
      <AssessmentsWizardTagsWidget assessmentSlug={details?.slug} />
    </SmartSelectSection>,
  ];

  return (
    <PageLoaderLayer path={PagePath.assessmentManagementWizardFOP}>
      <div className="wrapper stack" style={{ paddingBottom: 30, gap: 30 }}>
        <PageHeader
          breadcrumbs={[
            { name: 'Assessment Management', url: `${PagePath.assessmentManagementBase}` },
            { name: 'Wizard', url: `${PagePath.assessmentManagementBase}${PagePath.assessmentManagementWizard.replace(':companySlug', companyVariables.slug!)}` },
            { name: 'Fields of Practice Assessment', url: `${PagePath.assessmentManagementBase}${PagePath.assessmentManagementWizardFOP.replace(':companySlug', companyVariables.slug!)}` },
          ]}
          title="Fields of Practice Assessment"
          subtitle="Create an assessment and get skill scores for your learners"
          divider
        />

        <div style={{ width: '100%' }}>
          {/* TODO: fix SmartSelect so this isn't required */}
          <SmartSelect>{steps.filter((_, index) => index <= activeIndex)}</SmartSelect>
        </div>

        <footer className="card" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Anchor arrow reverse onClick={() => keplerNavigate(`${PagePath.assessmentManagementBase}${PagePath.assessmentManagementWizard.replace(':companySlug', companyVariables.slug!)}`)}>
            Back
          </Anchor>

          <div style={{ display: 'flex', flexDirection: 'row', gap: 15 }}>
            {activeIndex < steps.length - 1 || !details?.slug ? (
              <Tooltip content="Complete all the above steps">
                <Button type="button" disabled>
                  Activate
                </Button>
              </Tooltip>
            ) : (
              <Button
                type="button"
                onClick={async () => {
                  actions.startLoader({ path: PagePath.assessmentManagementWizardFOP, type: FetchType.Sending });

                  await actions.completeEndorsementSkillAssignment({ assessmentSlug: details.slug! });
                  await actions.activateSkillAssessment({ companySlug: companyVariables.slug!, assessmentSlug: details.slug! });

                  keplerNavigate(`${PagePath.assessmentManagementBase}${PagePath.assessmentManagementAllAssessments.replace(':companySlug', companyVariables.slug!)}`);
                  actions.stopLoader(PagePath.assessmentManagementWizardFOP);
                }}
              >
                Activate
              </Button>
            )}
          </div>
        </footer>
      </div>
    </PageLoaderLayer>
  );
}

