import React from 'react';
import { SkeletonLoaderContainer, SkeletonLoaderRow } from '../../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader.styles';
import { SkeletonLoader } from '../../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader';
import { PagePath } from '../../../../navigation/navigation.enums';

export function ViewRoleSkeleton({ path }: { path: PagePath.yourCareerRole | PagePath.roleManagementCompanyRole | PagePath.roleManagementGlobalRole }): JSX.Element {
  return (
    <SkeletonLoaderContainer>
      <SkeletonLoaderRow>
        <SkeletonLoader height="115px" />
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoader height="115px" />
      </SkeletonLoaderRow>

      {path !== PagePath.roleManagementGlobalRole && (
        <SkeletonLoaderRow>
          <SkeletonLoader height="490px" />
        </SkeletonLoaderRow>
      )}

      <SkeletonLoaderRow>
        <SkeletonLoader height="590px" />
      </SkeletonLoaderRow>
    </SkeletonLoaderContainer>
  );
}
