import React from 'react';
import styles from './stepper.module.css';
import { StepperProps } from './stepper.models';
import StepList from './step-list/step-list';

function Stepper({ steps, currentStepIndex, allowedSteps, goToStep }: StepperProps) {
  return (
    <div className={styles.stepper}>
      <StepList
        steps={steps}
        currentStepIndex={currentStepIndex}
        goToStep={goToStep}
        allowedSteps={allowedSteps}
      />
      <div className={styles.stepContent}>
        {steps[currentStepIndex - 1].content}
      </div>
    </div>
  );
}

export default Stepper;
