import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useAppState } from '../../overmind';
import { PagePath } from '../navigation.enums';
import { MyCareerPage } from '../../pages/administration/roles/view-role/my-career.page';

export function MyCareerRouter(): JSX.Element {
  const { user } = useAppState();

  return (
    <Routes>
      <Route path={PagePath.yourCareerRole} element={<MyCareerPage />} />

      <Route path={PagePath.root} element={<Navigate to={`${PagePath.yourCareerBase}${PagePath.yourCareerRole.replace(':roleSlug', user!.jobRoles[0].slug)}`} />} />
    </Routes>
  );
}