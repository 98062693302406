export enum PagePath {
  // account
  accountConfig = '/account/*',
  accountBase = '/account',
  accountLogin = '/login',
  accountRegister = '/register',
  accountSetPassword = '/set-password',
  accountForgotPassword = '/forgot-password',
  accountResetPassword = '/reset-password',
  accountVerifyEmail = '/verify-email',
  accountActivate = '/activate',

  // analysis
  analysisConfig = '/analysis/*',
  analysisBase = '/analysis',

  analysisInterimDownload = '/report',

  analysisTechnical = '/technical', // Champion
  analysisTechnicalCheckTeam = '/technical/check-team', // Champion
  analysisTechnicalAssignSkills = '/technical/assign-skills', // Champion
  analysisTechnicalSkillFrequency = '/technical/skill-frequency', // Learner

  analysisCareerPaths = '/career-paths',

  analysisRatingTechnical = '/rating/technical',
  analysisRatingTechnicalSelfAssessment = '/rating/technical/self-assessment', // Learner
  analysisRatingTechnicalPeerEndorsements = '/rating/technical/peer-endorsements',
  analysisRatingTechnicalPeerEndorsement = '/rating/technical/peer-endorsements/:ratingSlug',
  analysisRatingTechnicalCompleted = '/rating/technical/completed', // Champion

  analysisRatingCareerPaths = '/rating/career-paths',
  analysisRatingCareerPath = '/rating/career-paths/:ratingSlug',

  analysisBehavioural = '/behavioural',
  analysisBehaviouralQuestionnaire = '/behavioural/:questionnaireSlug',

  // your skills
  yourSkillsConfig = '/your-skills/*',
  yourSkillsBase = '/your-skills',
  yourSkillsSkill = '/skill/:skillSlug',

  // learning journey
  learningJourneyConfig = '/learning-journey/*',
  learningJourneyBase = '/learning-journey',
  learningJourneyCoreSkillsMap = '/core-skills-map',
  learningJourneySkill = '/skill/:skillSlug',
  learningJourneyYearSkill = '/year-skill/:skillSlug',
  learningJourneyAssignedSkill = '/assigned-skill/:skillSlug',
  learningJourneyCourseReflections = '/course-reflections',
  learningJourneyAdditionalLearning = '/additional-learning',
  learningJourneyCertificates = '/certificates',

  // my career
  yourCareerConfig = '/your-career/*',
  yourCareerBase = '/your-career',
  yourCareerRole = '/:roleSlug',

  // administration
  administrationConfig = '/administration/*',
  administrationBase = '/administration',
  administrationDepartments = '/:companySlug/departments',
  administrationDepartmentTeams = '/:companySlug/departments/:departmentSlug/teams',
  administrationDepartmentPeople = '/:companySlug/departments/:departmentSlug/people',
  administrationTeams = '/:companySlug/teams',
  administrationTeamPeople = '/:companySlug/teams/:teamSlug/people',
  administrationPeople = '/:companySlug/people',
  administrationPeopleBulkUpload = '/:companySlug/people/bulk-upload',
  administrationTags = '/:companySlug/tags',
  administrationSettings = '/:companySlug/settings',

  // analytics
  analyticsConfig = '/analytics/*',
  analyticsBase = '/analytics',
  analyticsOverview = '/:companySlug/overview',
  analyticsDepartments = '/:companySlug/departments',
  analyticsDepartment = '/:companySlug/departments/:departmentSlug',
  analyticsTeams = '/:companySlug/teams',
  analyticsTeam = '/:companySlug/teams/:teamSlug',
  analyticsRoles = '/:companySlug/roles',
  analyticsRole = '/:companySlug/roles/:roleSlug',
  analyticsSkills = '/:companySlug/skills',
  analyticsSkill = '/:companySlug/skills/:skillSlug',
  analyticsComparisonTools = '/:companySlug/comparison-tools',
  analyticsSkillSubType = '/:companySlug/skills/:skillSubTypeSlug/:skillSlug',
  analyticsEngagement = '/:companySlug/engagement',
  analyticsPeople = '/:companySlug/people',
  analyticsPerson = '/:companySlug/people/:personSlug',
  analyticsReportTemplates = '/:companySlug/report-templates',
  analyticsReportTemplate = '/:companySlug/report-templates/:reportSlug',
  analyticsReportTemplateName = '/:companySlug/report-templates/:reportSlug/:reportName',

  // assessment management
  assessmentManagementConfig = '/assessment-management/*',
  assessmentManagementBase = '/assessment-management',
  assessmentManagementAllAssessments = '/:companySlug/all-assessments',

  assessmentManagementWizard = '/:companySlug/wizard',
  assessmentManagementWizardPeer = '/:companySlug/wizard/peer',
  assessmentManagementWizardPeerUpdate = '/:companySlug/wizard/peer/update/:assessmentSlug',
  assessmentManagementWizardQuestionnaire = '/:companySlug/wizard/questionnaire',
  assessmentManagementWizardFOP = '/:companySlug/wizard/fop',

  assessmentManagementCreate = '/:companySlug/create',
  assessmentManagementUpdate = '/:companySlug/update/:assessmentSlug',
  assessmentManagementAssignees = '/:companySlug/assignees/:assessmentSlug',

  // learning management
  learningManagementConfig = '/learning-management/*',
  learningManagementBase = '/learning-management',
  learningManagementCourseMapping = '/:companySlug',
  learningManagementCourseMappingCourse = '/:companySlug/:courseSlug',
  learningManagementMappedCourses = '/:companySlug/mapped-courses',

  // report management
  reportManagement = '/report-management/:companySlug',

  // skills
  skillsConfig = '/skills/*',
  skillsBase = '/skills',
  skills = '/:companySlug',

  // role management
  roleManagementConfig = '/role-management/*',
  roleManagementBase = '/role-management',
  roleManagementRoles = '/:companySlug',
  roleManagementCreateRole = '/:companySlug/create',
  roleManagementEditRole = '/:companySlug/edit/:roleSlug',
  roleManagementImportRole = '/:companySlug/import/:roleSlug',
  roleManagementCompanyRole = '/:companySlug/company/:roleSlug',
  roleManagementGlobalRole = '/:companySlug/global/:roleSlug',

  // other
  onboarding = '/onboarding',
  profile = '/profile',
  companies = '/companies',
  dashboard = '/dashboard',
  yourAssessments = '/your-assessments',
  version = '/version',

  // error
  error = '/error/:type/:code',
  errorRouting404 = '/error/routing/404',

  // root
  root = '/',
  rootWildcard = '/*',
}

export enum Mode {
  PlatformManagement = 'PlatformManagement',
  LearningDashboard = 'LearningDashboard',
}
