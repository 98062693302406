import React from 'react';
import { IAssessmentsOverviewTableProps } from '../all-assessments/all-assessments.models';
import { Chip, ChipWrapper, KebabMenu, MenuItem, Pager, Table, TableColumn, TableRow } from '@keplerco/core';
import { PagerConnector } from '../../../components/general/pager-connector/pager-connector';
import { SkillAssessment } from '../../../models';
import { AssessmentType } from '../../../enums/assessment-type';

export function SkillAssessmentView(props: IAssessmentsOverviewTableProps): JSX.Element {
  function generateType(skillAssessment: SkillAssessment): string {
    if (skillAssessment.assessmentType === AssessmentType.PeerEndorsement) return 'Peer Endorsement';
    if (skillAssessment.assessmentType === AssessmentType.FieldsOfPractice) return 'Fields of Practice';
    return 'Questionnaire';
  }

  function generateStatusChip(skillAssessment: SkillAssessment): JSX.Element {
    if (skillAssessment.isActive) return <Chip label="Active" variant="tiny" backgroundColour="a" />;

    if (skillAssessment.isArchived) return <Chip label="Archived" variant="tiny" backgroundColour="yellow" />;

    if (skillAssessment.isComplete) return <Chip label="Closed" variant="tiny" backgroundColour="g" />;

    if (skillAssessment.isScheduled)
      return (
        <ChipWrapper>
          <Chip label="Scheduled" variant="tiny" backgroundColour="lilac" />
          <Chip label="Draft" variant="tiny" backgroundColour="transparent" borderColour="default" colour="default" />
        </ChipWrapper>
      );


    return <Chip label="Draft" variant="tiny" />;
  }

  function filterKebabMenuItems(skillAssessment: SkillAssessment): MenuItem[] {
    const kebabMenuItems: MenuItem[] = [
      {
        label: 'Activate',
        onClick: () => props.onClickActivateHandler(skillAssessment),
      },
      {
        label: 'Archive Assessment',
        onClick: () => props.onClickArchiveDraftAssessmentHandler(skillAssessment),
      },
      {
        label: 'Edit Assessment',
        onClick: () => props.onClickManageAssessmentDraftHandler(skillAssessment),
      },
      {
        label: 'Close',
        onClick: () => props.onClickCompleteHandler(skillAssessment),
      },
      {
        label: 'View Assignees',
        onClick: () => props.onClickViewAssigneesHandler(skillAssessment),
      },
    ];

    const hasSkills = skillAssessment.hasSkills || skillAssessment.questionnaireSlugs.length > 0;
    const hasAssignees = skillAssessment.numberOfAssignments && skillAssessment.numberOfAssignments > 0;
    const isNotActive = !skillAssessment.isActive;
    const isNotComplete = !skillAssessment.isComplete;
    const isActive = skillAssessment.isActive;
    const isNotArchived = !skillAssessment.isArchived;

    return kebabMenuItems.filter(item => {
      switch (item.label) {
        case 'Activate':
          return isNotActive && isNotComplete && hasSkills && hasAssignees;
        case 'View Assignees':
          return (hasAssignees && skillAssessment.isActive) || skillAssessment.isComplete;
        case 'Close':
          return isActive && isNotComplete;
        case 'Edit Assessment':
          return !skillAssessment.isActive && !skillAssessment.isComplete && !skillAssessment.includeCareerPaths;
        case 'Archive Assessment':
          return isNotArchived && isNotActive;
        default:
          return true;
      }
    });
  }

  return (
    <Table
      currentSortBy={String(props.request?.sortField)}
      onSort={props.onSortHandler}
      currentSortDirection={props.request?.sortAscending ? 'Ascending' : 'Descending'}
      onConfigClicked={props.onConfigClicked}
      footerContent={
        <PagerConnector onPageChange={props.onPageChangeHandler} defaultPageNumber={props.request?.page ?? 1} pageCount={props.totalPages}>
          {connector => {
            return <Pager {...connector} />;
          }}
        </PagerConnector>
      }
    >
      {props.skillAssessments.map((skillAssessment, index) => {
        const isLoading = props.loadingSkillAssessments.includes(String(skillAssessment.id!));

        return (
          <TableRow
            configCell={() => {
              return <span style={{ visibility: isLoading ? 'hidden' : undefined, pointerEvents: isLoading ? 'none' : undefined }}>{filterKebabMenuItems(skillAssessment).length > 0 && <KebabMenu items={filterKebabMenuItems(skillAssessment)} />}</span>;
            }}
            id={index}
            key={index}
            isLoading={isLoading}
          >
            <TableColumn id="name" label="Skill assessment name">
              {skillAssessment.name}
            </TableColumn>

            <TableColumn hidden={!props.columnConfiguration.find(i => i.key === 'assessmentType')?.selected} id="assessmentType" label="Skill assessment type">
              {generateType(skillAssessment)}
            </TableColumn>

            <TableColumn hidden={!props.columnConfiguration.find(i => i.key === 'dateCreated')?.selected} id="dateCreated" label="Date created">
              {!!skillAssessment.dateCreated && new Date(skillAssessment.dateCreated).toLocaleString('en-UK', { dateStyle: 'long' })}
            </TableColumn>

            {isLoading ? (
              <TableColumn hidden={!props.columnConfiguration.find(i => i.key === 'Status')?.selected} id="Status" label="Status">
                <Chip label="Updating" variant="tiny" backgroundColour="a" colour="white" />
              </TableColumn>
            ) : (
              <TableColumn hidden={!props.columnConfiguration.find(i => i.key === 'Status')?.selected} id="status" label="Status">
                {generateStatusChip(skillAssessment)}
              </TableColumn>
            )}
          </TableRow>
        );
      })}
    </Table>
  );
}

