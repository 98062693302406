import React, { Fragment, useEffect, useState } from 'react';
import { CompletionStatus, FetchType } from '../../../../enums';
import { OrganizationLevelType } from '../../../../enums/organization-level';
import { KeplerState } from '../../../../models/kepler-state';
import { useAppActions, useAppState } from '../../../../overmind';
import { AnalyticsGridSection } from '../../analytics.styles';
import { ContinueLearningWidget } from '../../../../widgets/continue-learning/continue-learning';
import { AdditionalLearningFocusPanelLayout } from '../../../learning-journey/additional-learning/additional-learning-focus-panel/additional-learning-focus-panel.layout';
import { AdditionalLearningViewModel } from '../../../learning-journey/additional-learning/additional-learning.models';
import { NotificationPriority, NotificationType } from '../../../../notifications/notifications.models';
import { SkeletonLoader } from '../../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader';
import { ReflectionFocusPanelLayout } from '../../../learning-journey/reflections/reflection-focus-panel.layout';
import { IPersonLayoutProps } from './person.models';
import { PagePath } from '../../../../navigation/navigation.enums';
import { PermissionsEntity } from '../../../../widgets/analytics/permissions-entity-dropdown/permissions-entity-dropdown.models';
import { PersonDetails } from './person-details/person-details';
import { SkeletonLoaderColumn, SkeletonLoaderRow } from '../../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader.styles';
import { ActionCard, PageHeader, CascadingPanels, useCascadingPanelsConnector, CascadingPanel } from '@keplerco/core';
import { useKeplerNavigate } from '../../../../navigation/guards/use-kepler-navigate';
import { EntityAverageWidget } from '../../../../components/general/average/entity-average/entity-average.widget';
import { DailyActivityWidget, SkillsBreakdownWidget, SkillScoreOverTimeWidget } from '../../../../widgets';
import { CourseEngagementWidget } from '../../../../widgets/analytics/overview/course-engagement.widget';
import classNames from 'classnames';

enum CascadingPanelIds {
  AdditionalLearning = 'AdditionalLearning',
  Reflection = 'Reflection',
}

export function PersonLayout(props: IPersonLayoutProps): JSX.Element {
  const { loading, path, slug, firstName, lastName, email, department, jobTitle, keplerPoints, lessons, breadcrumbs: crumbs } = props;

  const actions = useAppActions();
  const { user } = useAppState<KeplerState>();

  const keplerNavigate = useKeplerNavigate();

  const { openPanelIds, next, close, closeAll } = useCascadingPanelsConnector();

  const [entity, setEntity] = useState<PermissionsEntity>();
  const [pendingLearnerAssessmentsCount, setPendingLearnerAssessmentsCount] = useState<number>(0);
  const [selectedReflectionId, setSelectedReflectionId] = useState<number>();

  useEffect(() => {
    async function getData() {
      if (path !== PagePath.dashboard) return;
      const result = await actions.getLearnerAssessments({ pageSize: 50, page: 1 });
      if (!!result) setPendingLearnerAssessmentsCount(result.filter(value => value.isActive && value.completionStatus !== CompletionStatus.Completed).length);
    }
    getData();
  }, []);

  useEffect(() => {
    if (loading) return;
    setEntity({ organizationLevel: OrganizationLevelType.Learner, entity: { slug: slug, name: `${firstName} ${lastName}` } });
  }, [loading, slug, firstName]);

  // additional learning
  async function onSubmitAdditionalLearningHandler(model: AdditionalLearningViewModel, reflect?: boolean) {
    actions.startLoader({ path: path, type: FetchType.Sending });

    const additionalLearning = await actions.saveAdditionalLearning(model);

    if (!additionalLearning) {
      closeAll();
      actions.stopLoader(path);
      return;
    }

    actions.addNotification({
      title: 'Additional Learning Captured',
      message: `Your Additional Learning - ${model.name} - has been captured.`,
      priority: NotificationPriority.Toast,
      type: NotificationType.Success,
      id: `additional_learning_${Date.now() + Math.random()}`,
      active: true,
    });

    if (reflect) {
      setSelectedReflectionId(additionalLearning.reflectionId);
      next(CascadingPanelIds.Reflection);
      actions.stopLoader(path);
      return;
    }

    closeAll();
    actions.stopLoader(path);
  }

  // reflection
  function onCloseReflectionHandler() {
    setSelectedReflectionId(undefined);
    closeAll();
  }

  return (
    <React.Fragment>
      <div className="pageWrapper wrapper" id={path === PagePath.dashboard ? 'dashboard-page' : 'person-page'}>
        {path !== PagePath.dashboard && (
          <Fragment>
            {loading ? (
              <SkeletonLoader height="25px" width="50%" />
            ) : (
              <Fragment>
                <PageHeader breadcrumbs={crumbs} divider />

                <br />
              </Fragment>
            )}
          </Fragment>
        )}

        {loading ? (
          <SkeletonLoaderRow style={{ marginTop: 20, marginBottom: 20 }}>
            <SkeletonLoaderColumn>
              <SkeletonLoader height="130px" />
            </SkeletonLoaderColumn>
          </SkeletonLoaderRow>
        ) : (
          <PersonDetails path={path} slug={slug} firstName={firstName} lastName={lastName} jobTitle={jobTitle} department={department} email={email} keplerPoints={keplerPoints} />
        )}

        {!!pendingLearnerAssessmentsCount && (
          <AnalyticsGridSection>
            <div className="full-span">
              <ActionCard
                icon="/assessments-pending.svg"
                title={`Assessment${pendingLearnerAssessmentsCount === 1 ? '' : `s`} pending`}
                description={`You currently have ${pendingLearnerAssessmentsCount} assessments pending`}
                action={{
                  type: `anchor`,
                  text: `View Assessments`,
                  onClick: () => keplerNavigate(PagePath.yourAssessments),
                }}
              />
            </div>
          </AnalyticsGridSection>
        )}

        <AnalyticsGridSection>
          <div className="start-1" id="entity-average-widget-section">
            <EntityAverageWidget path={path} entity={entity} />
          </div>

          <div className="end-2" id="skills-breakdown-section">
            <SkillsBreakdownWidget path={path} entity={entity} />
          </div>
        </AnalyticsGridSection>

        <AnalyticsGridSection>
          <div className="full-span">
            <DailyActivityWidget path={path} entity={entity} />
          </div>
        </AnalyticsGridSection>

        <AnalyticsGridSection id="overview-section">
          <div className="full-span">
            <CourseEngagementWidget entity={entity} isLearner={path === PagePath.analyticsPerson} />
          </div>
        </AnalyticsGridSection>

        <AnalyticsGridSection id="skill-score-over-time-section">
          <div className="full-span">
            <SkillScoreOverTimeWidget path={path} entity={entity} />
          </div>
        </AnalyticsGridSection>

        {!!lessons && user?.allowOffPlatformLearning && (
          <div className={classNames('card', 'full-span')} style={{ marginTop: 40 }}>
            <ContinueLearningWidget lessons={lessons} onClickCaptureAdditionalLearning={() => next(CascadingPanelIds.AdditionalLearning)} />
          </div>
        )}

        {/* TODO: unify additional learning and reflection panel combinations into a component */}
        {path !== PagePath.analyticsPerson && (
          <CascadingPanels
            openPanelIds={openPanelIds}
            onClosePanel={id => {
              if (id === CascadingPanelIds.Reflection) {
                onCloseReflectionHandler();
              }

              close(id);
            }}
          >
            <CascadingPanel id={CascadingPanelIds.AdditionalLearning}>
              <AdditionalLearningFocusPanelLayout path={path} onCancel={closeAll} onSubmit={onSubmitAdditionalLearningHandler} />
            </CascadingPanel>

            <CascadingPanel id={CascadingPanelIds.Reflection}>
              <ReflectionFocusPanelLayout reflectionId={selectedReflectionId} path={path} onCancel={onCloseReflectionHandler} onSubmit={onCloseReflectionHandler} />
            </CascadingPanel>
          </CascadingPanels>
        )}
      </div>
    </React.Fragment>
  );
}
