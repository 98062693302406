import { Anchor, FieldController, GridItemLayout, GridLayout, ListItemLayout, ListLayout, RadioButtonCard, TextField, ToggleCard, useMatchScreenWidth } from '@keplerco/core';
import React, { Fragment, useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useAppActions, useAppState } from '../../../../../overmind';
import { AssessmentsWizardDetails } from '../../assessments-wizard-peer.page';
import { AssessmentType } from '../../../../../enums/assessment-type';
import { NotificationPriority, NotificationType } from '../../../../../notifications/notifications.models';
import { AssessmentPreferencesRequest, AssessmentPreferencesResponse } from '../../../../../models/overmind/assessment-preferences';
import classNames from 'classnames';
import styles from './details-step.module.css';
import { DetailsStepSkeleton } from './details-step.skeleton';
import { IDetailsStepLayoutProps } from './details-step.models';
import { EntitiesCardWidget } from '../../../../../components/cards/entities-card.widget';

export function DetailsStepLayout(props: IDetailsStepLayoutProps): JSX.Element {
  const { isLoading, details, assessmentType, nextPreferences, setDetails, setNextPreferences, setAssessmentSlug, completeStep, assessmentSlug } = props;

  const actions = useAppActions();
  const { companyVariables } = useAppState();
  const { getValues, control, trigger, reset, watch } = useForm<any>({
    defaultValues: {
      name: details?.name,
      points: details?.keplerPointsAvailable,
    },
  });

  const [loading, setLoading] = useState<boolean>(isLoading);
  const [dirty, setDirty] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<boolean>(false);
  const [canProceed, setCanProceed] = useState(false);
  const isMobile = useMatchScreenWidth('mobile');
  const [fieldValues, setFieldValues] = useState<FieldValues>();

  useEffect(() => {
    watch(setFieldValues);
  }, []);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    setErrorMessage(assessmentType === AssessmentType.PeerEndorsement && !nextPreferences.allowSelfReview && !nextPreferences.allowPeerReview && !nextPreferences.allowManagerReview);
  }, [nextPreferences.allowSelfReview, nextPreferences.allowPeerReview, nextPreferences.allowManagerReview]);

  useEffect(() => {
    // TODO: scroll to errors as an enhancement
    setCanProceed(!!fieldValues?.name && !!fieldValues?.points && !errorMessage);
  }, [fieldValues, errorMessage]);

  useEffect(() => {
    reset({
      name: details?.name || '',
      points: details?.keplerPointsAvailable?.toString() || '',
    });
  }, [reset, details]);

  async function onHandleNext() {
    setDirty(true);

    if (assessmentType === AssessmentType.PeerEndorsement && !canProceed) return;

    if (assessmentType !== AssessmentType.PeerEndorsement && (nextPreferences.minScore === undefined || nextPreferences.maxScore === undefined || nextPreferences.minScore >= nextPreferences.maxScore || nextPreferences.minScore < 0 || nextPreferences.maxScore > 100)) {
      return;
    }

    setLoading(true);
    const { name, points } = getValues();

    const detailsRequest: AssessmentsWizardDetails = {
      companySlug: companyVariables.slug!,
      slug: assessmentSlug || undefined,
      assessmentType,
      name,
      keplerPointsAvailable: parseInt(points),
    };

    const response = await actions.postAssessmentDetails(detailsRequest);

    if (!!response) {
      setAssessmentSlug(response);
      detailsRequest.slug = response;
      setDetails(detailsRequest);

      const preferencesRequest: AssessmentPreferencesRequest & AssessmentPreferencesResponse = {
        ...nextPreferences,
        assessmentSlug: response,
        companySlug: companyVariables.slug!,
      };

      await actions.putAssessmentPreferences(preferencesRequest);
      setNextPreferences(nextPreferences);
      setLoading(false);
      completeStep();
    } else {
      // Handle failure case
      setLoading(false);
      actions.addNotification({
        active: true,
        id: crypto.randomUUID(),
        type: NotificationType.Error,
        priority: NotificationPriority.Toast,
        title: 'Something went wrong saving these details',
        message: 'Please try again',
        toastIncludeIcon: true,
      });
    }
  }

  if (loading) {
    return <DetailsStepSkeleton />;
  }

  return (
    <div className={styles.layout}>
      <EntitiesCardWidget title="Assessment details" description=" Name your assessment, and choose how many Kepler Points you would like to award">
        <form id="assessmentDetails">
          <div style={{ display: 'grid', gridTemplateColumns: isMobile ? '1fr' : '1fr auto', gap: 15 }}>
            <FieldController
              name="name"
              control={control}
              rules={{
                required: 'Please enter a name',
                pattern: {
                  value: /^[A-Za-z0-9\s-_]+$/,
                  message: 'Only letters, numbers, spaces, hyphens, and underscores are allowed (A-Z, a-z, 0-9, _, -)',
                },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label="Assessment name"
                  type="text"
                  validation={{
                    dirty: fieldState.isDirty || !!fieldState.error,
                    invalid: !!fieldState.error,
                    message: fieldState.error?.message ?? 'Please enter a name',
                  }}
                  responsive
                  onBlur={() => trigger('name')}
                />
              )}
            />

            <FieldController
              name="points"
              control={control}
              rules={{
                required: 'Please enter number of points',
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'Only numeric values starting from 1 are allowed',
                },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label="Kepler Points"
                  type="number"
                  validation={{
                    dirty: fieldState.isDirty || !!fieldState.error,
                    invalid: !!fieldState.error,
                    message: fieldState.error?.message ?? 'Please enter number of points',
                  }}
                  responsive
                  onBlur={() => trigger('points')}
                />
              )}
            />
          </div>
        </form>
      </EntitiesCardWidget>

      <EntitiesCardWidget title="Preferences" description="Choose the preferences for this assessment.">
        {assessmentType === AssessmentType.PeerEndorsement ? (
          <div>
            <ListLayout>
              <ListItemLayout>
                <ToggleCard
                  id="allowSelfReview"
                  value={nextPreferences.allowSelfReview!}
                  onChange={(_, value) => {
                    const nextState = { ...nextPreferences, allowSelfReview: value };
                    setNextPreferences(nextState);
                  }}
                >
                  <div>
                    <h6 className="subtitle">Self Assessment</h6>
                    {!isMobile && (
                      <p className="caption1" style={{ color: 'var(--accent-2' }}>
                        Participants will assess themselves against assigned skills
                      </p>
                    )}
                  </div>
                </ToggleCard>
              </ListItemLayout>

              <ListItemLayout>
                <ToggleCard
                  id="allowManagerReview"
                  value={nextPreferences?.allowManagerReview ?? false}
                  onChange={(_, value) => {
                    const nextState = { ...nextPreferences, allowManagerReview: value };
                    setNextPreferences(nextState);
                  }}
                >
                  <div className={styles.toggleCardChildren}>
                    <h6 className="subtitle">Manager Assessment</h6>

                    {!isMobile && (
                      <p className="caption1" style={{ color: 'var(--accent-2' }}>
                        The manager and the employee will be given a chance to rate the employee's skills
                      </p>
                    )}
                  </div>
                </ToggleCard>
              </ListItemLayout>

              <ListItemLayout>
                <ToggleCard
                  id="allowPeerReview"
                  value={!!nextPreferences?.allowPeerReview}
                  onChange={(_, value) => {
                    const nextState = { ...nextPreferences, allowPeerReview: value };
                    setNextPreferences(nextState);
                  }}
                >
                  <div className={styles.toggleCardChildren}>
                    <h6 className="subtitle">Peer Assessment</h6>

                    {!isMobile && (
                      <p className="caption1" style={{ color: 'var(--accent-2' }}>
                        The entire peer group will rate one another on the assigned skills
                      </p>
                    )}
                  </div>
                </ToggleCard>
              </ListItemLayout>
            </ListLayout>

            {!!dirty && errorMessage && (
              <div className={classNames('formErrorMessage', { invisible: !dirty || !errorMessage })} style={{ marginTop: 15 }}>
                Activate at least one assessment toggle
              </div>
            )}
          </div>
        ) : (
          <Fragment>
            <div>
              <div className="heading5">Reassessment</div>

              <div className="row">
                <ToggleCard
                  id="allowReassessment"
                  value={!!nextPreferences?.allowReassessment}
                  onChange={(_, value) => {
                    const nextState = { ...nextPreferences, allowReassessment: value };
                    setNextPreferences(nextState);
                  }}
                >
                  <h6 className="subtitle">Allow reassessment</h6>

                  {!isMobile && (
                    <p className="caption1" style={{ color: 'var(--accent-2' }}>
                      Allow the learners to reset and retake the assessment by themselves
                    </p>
                  )}
                </ToggleCard>
              </div>
            </div>

            <div>
              <div className="heading5">Assessment scores</div>

              <div className="row">
                <div className="column">
                  <TextField
                    label="Minimum score"
                    type="number"
                    defaultValue={nextPreferences.minScore}
                    min={0}
                    onChange={event => {
                      const nextState = { ...nextPreferences, minScore: parseInt(event.target.value) };
                      setNextPreferences(nextState);
                    }}
                    responsive
                    validation={{
                      dirty: dirty,
                      invalid: nextPreferences.minScore === undefined || nextPreferences.minScore >= nextPreferences.maxScore || nextPreferences.minScore < 0,
                      message: 'Minimum score is required and must be less than maximum score and greater than 0',
                    }}
                  />
                </div>

                <div className="column">
                  <TextField
                    label="Maximum score"
                    type="number"
                    defaultValue={nextPreferences.maxScore}
                    max={100}
                    onChange={event => {
                      const nextState = { ...nextPreferences, maxScore: parseInt(event.target.value) };
                      setNextPreferences(nextState);
                    }}
                    responsive
                    validation={{
                      dirty: dirty,
                      invalid: nextPreferences.maxScore === undefined || nextPreferences.minScore >= nextPreferences.maxScore || nextPreferences.maxScore > 100,
                      message: 'Maximum score is required and must be greater than minimum score and less than 100',
                    }}
                  />
                </div>
              </div>
            </div>
          </Fragment>
        )}

        <div style={{ display: 'flex', flexDirection: 'column', gap: 10, marginTop: 20 }}>
          <div className="heading5">Do you want to force participants to complete of this assessment once activated?</div>

          {!isMobile && (
            <p className="caption1" style={{ color: 'var(--accent-2)', marginBottom: 10 }}>
              Force participants to complete an assessment, the next time someone logs in they will be required to complete the assessment before continuing.
            </p>
          )}

          <GridLayout columnCount={isMobile ? 1 : 2}>
            <GridItemLayout>
              <RadioButtonCard
                id="forceAssessmentTrue"
                checked={!!nextPreferences?.forceAssessment}
                onChange={() => {
                  const nextState = { ...nextPreferences, forceAssessment: true };
                  setNextPreferences(nextState);
                }}
              >
                <div className="card">Yes, make this compulsory</div>
              </RadioButtonCard>
            </GridItemLayout>

            <GridItemLayout>
              <RadioButtonCard
                id="forceAssessmentFalse"
                checked={!nextPreferences?.forceAssessment}
                onChange={() => {
                  const nextState = { ...nextPreferences, forceAssessment: false };
                  setNextPreferences(nextState);
                }}
              >
                <div className="card">No, make optional</div>
              </RadioButtonCard>
            </GridItemLayout>
          </GridLayout>

          <p className={classNames('caption1', { invisible: !nextPreferences.forceAssessment })} style={{ color: 'var(--accent-2)' }}>
            Great! We'll let people know to complete the assessment.
          </p>
        </div>
      </EntitiesCardWidget>

      <footer className="card" style={{ display: 'flex', alignContent: 'center', justifyContent: 'flex-end', background: 'var(--background)' }}>
        <Anchor arrow onClick={onHandleNext}>
          Next
        </Anchor>
      </footer>
    </div>
  );
}

