import { IconProps } from '@keplerco/core';
import React from 'react';

export function QuestionnaireIcon({ size = 80 }: IconProps): JSX.Element {
  return (
    <svg width={size} height={size} viewBox="0 0 100 101" fill="none">
      <circle cx="50" cy="50.5" r="39.3921" stroke="var(--blue)" strokeWidth="1.21586" />
      <mask id="mask0_4002_9215" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="17" y="17" width="66" height="67">
        <circle cx="50" cy="50.5" r="33" fill="var(--black)" />
      </mask>
      <g mask="url(#mask0_4002_9215)">
        <circle cx="49.8282" cy="50.3282" r="32.8282" fill="var(--white)" />
        <circle cx="49.8282" cy="50.3282" r="32.8282" fill="var(--blue)" />
        <path
          d="M-16.8926 69.0464H37.1696L41.657 71.0118L45.594 71.3608L47.5154 65.515L44.2072 64.3861L42.2136 70.0087L41.201 69.5873L43.1161 64.0653L39.4211 63.254C38.83 65.0087 38.1287 66.7068 37.5469 68.4961L40.7136 68.9804C41.0752 67.9647 41.4337 66.949 41.7953 65.9332C42.1758 64.8641 42.5563 63.798 42.9337 62.7289C42.1601 62.3673 41.2985 61.993 40.5469 61.6C41.6947 61.7226 42.962 61.9522 44.116 62.1283C44.3802 62.1691 44.2827 62.1377 44.4274 61.7163C45.7387 57.8673 46.9368 53.8328 50.8015 51.7951C54.3266 49.9366 58.1347 49.6347 59.3706 45.075C60.2259 41.9178 58.4964 39.1003 55.4775 38.0091C54.9146 37.8236 53.5279 37.7009 52.9587 37.8456C55.0027 39.16 55.8109 40.9713 55.7417 43.3423C55.5656 49.207 43.4085 48.3454 40.6916 60.9773C41.3991 61.1723 42.9431 61.2761 43.7481 61.3641L44.0783 61.3987L48.1034 63.5622C49.0342 60.5182 50.3235 56.9365 53.3926 55.5403C57.641 53.6001 61.8517 52.773 63.4648 47.6473C64.5938 44.0625 63.9931 39.8613 61.8831 36.7387C59.6567 33.4431 56.1222 31.789 52.204 33.201C48.8613 34.4116 47.2009 37.5563 45.6789 40.5594C45.3928 41.1223 45.3959 40.9619 46.028 41.2889C46.8896 41.7355 47.7512 42.1726 48.638 42.5593C50.0814 40.0436 49.7355 39.6946 52.5184 38.0563C49.4682 37.7701 48.8738 40.4179 47.7166 42.6191C47.16 42.5373 45.0248 42.2323 44.6443 42.0499C43.6695 41.5845 42.572 41.0751 41.6255 40.5688C41.3519 40.421 41.7073 40.5279 41.7859 40.5342C42.8456 40.6474 43.8299 40.8361 44.9053 40.9053C45.3833 40.1663 45.3424 40.3204 44.245 40.2323C43.4745 40.1663 42.55 40.1537 41.8079 39.9933C43.5657 32.8331 49.0216 32.4588 55.2134 32.5029C73.1031 32.5658 90.9991 32.5029 108.892 32.5029"
          stroke="var(--black)"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
      </g>
    </svg>
  );
}
