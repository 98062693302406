import { Anchor, FieldController, TextField } from '@keplerco/core';
import React, { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useAppActions, useAppState } from '../../../overmind';
import { AssessmentsWizardDetails } from './assessments-wizard-peer.page';
import { AssessmentType } from '../../../enums/assessment-type';
import { SkeletonLoader } from '../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader';
import { NotificationPriority, NotificationType } from '../../../notifications/notifications.models';

export function AssessmentsWizardDetailsWidget({ assessmentType, onSaveAndContinue }: { assessmentType: AssessmentType; onSaveAndContinue: (details: AssessmentsWizardDetails) => void }): JSX.Element {
  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const { watch, getValues, control, trigger } = useForm();

  const [loading, setLoading] = useState<boolean>(false);
  const [fieldValues, setFieldValues] = useState<FieldValues>();

  useEffect(() => {
    watch(() => setFieldValues(getValues()));
  }, []);

  if (loading) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: 25 }}>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 300px', gap: 15 }}>
          <SkeletonLoader height="45px" />

          <SkeletonLoader height="45px" />
        </div>

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <SkeletonLoader width="150px" height="25px" />
        </div>
      </div>
    );
  }

  return (
    <form id="assessmentDetails">
      <div style={{ display: 'grid', gridTemplateColumns: '1fr auto', gap: 15 }}>
        <FieldController
          name="name"
          control={control}
          rules={{
            required: 'Please enter a name',
            pattern: {
              value: /^[A-Za-z0-9\s-_]+$/,
              message: 'Please enter valid characters',
            },
          }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              label="Name"
              type="text"
              validation={{
                dirty: fieldState.isDirty || !!fieldState.error,
                invalid: !!fieldState.error,
                message: fieldState.error?.message ?? 'Please enter a name',
              }}
              responsive
              onBlur={() => trigger('name')}
            />
          )}
        />

        <FieldController
          name="points"
          control={control}
          rules={{
            required: 'Please enter a value',
            pattern: {
              value: /^[0-9]+$/,
              message: 'Please enter a valid value',
            },
          }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              label="Kepler Points"
              type="number"
              validation={{
                dirty: fieldState.isDirty || !!fieldState.error,
                invalid: !!fieldState.error,
                message: fieldState.error?.message ?? 'Please enter a value',
              }}
              onBlur={() => trigger('points')}
            />
          )}
        />
      </div>

      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <Anchor
          arrow
          onClick={async () => {
            if (!fieldValues?.name || !fieldValues.points) return trigger(['name', 'points']);

            setLoading(true);

            const request: AssessmentsWizardDetails = {
              companySlug: companyVariables.slug!,
              slug: undefined,
              assessmentType,
              name: fieldValues.name,
              keplerPointsAvailable: parseInt(fieldValues.points),
            };
            const response = await actions.postAssessmentDetails(request);

            setLoading(false);

            !response
              ? actions.addNotification({
                active: true,
                id: crypto.randomUUID(),
                type: NotificationType.Error,
                priority: NotificationPriority.Toast,
                title: 'Something went wrong saving these details',
                message: 'Please try again',
                toastIncludeIcon: true,
              })
              : onSaveAndContinue({ ...request, slug: response });
          }}
        >
          Save & Continue
        </Anchor>
      </div>
    </form>
  );
}

