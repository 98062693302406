import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useAppActions, useAppState } from '../../../../overmind';
import { SkillAssessment } from '../../../../models';
import { FetchStatus, FetchType, SkillAssessmentAssignee } from '../../../../enums';
import * as assessmentDetailsWidgetStyles from './assessment-details.widget.styles';
import { KeplerState } from '../../../../models/kepler-state';
import { WidgetSkeleton } from '../widget.skeleton';
import { PagePath } from '../../../../navigation/navigation.enums';
import classNames from 'classnames';
import { AssessmentType } from '../../../../enums/assessment-type';
import { FormControl, RadioButtonCard, Textfield, safeCallback } from '@keplerco/core';

interface ISkillAssessmentDetailsWidgetProps {
  onSkillAssessmentSlugCreated: (slug: string) => void;
  onSkillAssessmentIdCreated: (id: number) => void;
  onStepComplete: (slug: string) => void;
  assessmentDraftDetails?: SkillAssessment | null;
  onRadioChange: (option: any) => void;
  slug?: string;
}

export function SkillAssessmentDetailsWidget({ onRadioChange, onSkillAssessmentSlugCreated, onSkillAssessmentIdCreated, onStepComplete, assessmentDraftDetails, slug: assessmentSlug }: ISkillAssessmentDetailsWidgetProps): JSX.Element {
  const { handleSubmit, control, setValue, watch } = useForm<any>();
  const params = useParams();
  const actions = useAppActions();
  const isDraft = assessmentDraftDetails !== null;
  const formValues = watch();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [nameEdited, setNameEdited] = useState<boolean>(false);

  const initialFormValuesRef = React.useRef<any>();

  React.useEffect(() => {
    if (assessmentDraftDetails) {
      let assessmentType: string = 'questionnaire';

      if (assessmentDraftDetails.assessmentType === AssessmentType.PeerEndorsement) assessmentType = 'peerEndorsement';
      else if (assessmentDraftDetails.assessmentType === AssessmentType.Questionnaire) assessmentType = 'questionnaire';
      else if (assessmentDraftDetails.assessmentType === AssessmentType.FieldsOfPractice) assessmentType = 'fieldsOfPractice';

      const initialValues = {
        assessmentName: assessmentDraftDetails.name,
        points: assessmentDraftDetails.keplerPointsAvailable,
        assessmentType,
      };

      initialFormValuesRef.current = initialValues;

      setValue('assessmentName', initialValues.assessmentName);
      setValue('points', initialValues.points);
      setValue('assessmentType', initialValues.assessmentType);
    }
  }, [assessmentDraftDetails, setValue]);

  React.useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'assessmentName' && initialFormValuesRef.current) {
        if (value.assessmentName !== initialFormValuesRef.current.assessmentName) {
          setNameEdited(true);
        } else {
          setNameEdited(false);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  React.useEffect(() => {
    onRadioChange(formValues.assessmentType);
  }, [formValues.assessmentType]);

  async function onSubmit(formData: any) {
    if (isSaving) return;

    setIsSaving(true);
    actions.startLoader({ path: PagePath.assessmentManagementCreate, type: FetchType.Custom });

    let typeOfAssessment: AssessmentType = AssessmentType.PeerEndorsement;
    if (formValues.assessmentType === 'questionnaire') typeOfAssessment = AssessmentType.Questionnaire;
    else if (formValues.assessmentType === 'peerEndorsement') typeOfAssessment = AssessmentType.PeerEndorsement;
    else if (formValues.assessmentType === 'fieldsOfPractice') typeOfAssessment = AssessmentType.FieldsOfPractice;

    const skillAssessmentModel: SkillAssessment = {
      assessmentType: typeOfAssessment,
      companySlug: params.companySlug!,
      name: formData.assessmentName,
      keplerPointsAvailable: parseInt(formData.points),
      includeTechnicalSkills: true,
      includeManagerSkillAdd: true,
      includeLearnerSkillAdd: false,
      includeLearnerSkillFrequency: true,
      includeManagePeerAssessment: true,
      includeLearnerPeerAssessment: true,
      assignementType: SkillAssessmentAssignee.Learner,
      slug: assessmentSlug ?? params.slug ?? '',
      includeBehavioralSkills: true,
      questionnaireSlugs: [],
      includeCareerPaths: typeOfAssessment === AssessmentType.FieldsOfPractice,
      careerPathSlugs: [],
      allowReassessment: false,
    };

    const result = await actions.updateSkillAssessment(skillAssessmentModel);

    if (result?.slug && result?.id) {
      safeCallback(onSkillAssessmentSlugCreated, result.slug);
      safeCallback(onSkillAssessmentIdCreated, result.id);
    }

    actions.stopLoader(PagePath.assessmentManagementCreate);
    setIsSaving(false);

    if (params.slug || nameEdited) {
      return void 0;
    } else if (!!result) {
      onStepComplete(result.slug!);
    }
  }

  function handleSaveClick() {
    handleSubmit(onSubmit)();
  }

  const { fetchState } = useAppState<KeplerState>();

  return fetchState[PagePath.assessmentManagementCreate].status === FetchStatus.Active && fetchState[PagePath.assessmentManagementCreate].type === FetchType.Custom ? (
    <WidgetSkeleton />
  ) : (
    <form autoComplete="off" id="assessmentDetails" onSubmit={handleSubmit(onSubmit)}>
      <assessmentDetailsWidgetStyles.AssessmentDetailsWidgetFormWrapper>
        <assessmentDetailsWidgetStyles.AssessmentDetailsWidgetAssessmentNameWrapper>
          <assessmentDetailsWidgetStyles.AssessmentDetailsWidgetInputLabel className="heading5">Assessment Name</assessmentDetailsWidgetStyles.AssessmentDetailsWidgetInputLabel>

          <FormControl
            name="assessmentName"
            control={control}
            // TODO for LettersValidator also so that it can be fixed on Core V2 and implemented here
            rules={{
              required: 'Assessment name is required',
              pattern: {
                value: /^[A-Za-z0-9\s-_]+$/,
                message: 'Invalid characters used',
              },
            }}
            render={({ field, fieldState }) => {
              return <Textfield {...field} haserror={!!fieldState.error} responsive />;
            }}
          />
        </assessmentDetailsWidgetStyles.AssessmentDetailsWidgetAssessmentNameWrapper>

        <assessmentDetailsWidgetStyles.AssessmentDetailsWidgetPointsInputWrapper>
          <assessmentDetailsWidgetStyles.AssessmentDetailsWidgetInputLabel className="heading5">Points</assessmentDetailsWidgetStyles.AssessmentDetailsWidgetInputLabel>

          <FormControl
            name="points"
            control={control}
            // TODO for NumbersValidator so that it can be fixed on Core V2 and implemented here
            rules={{
              required: 'Please enter a valid number of points',
              pattern: {
                value: /^[0-9]+$/,
                message: 'Please enter a valid number of points',
              },
            }}
            render={({ field, fieldState }) => {
              return <Textfield {...field} haserror={!!fieldState.error} type="text" responsive disabled={!!assessmentSlug} />;
            }}
          />
        </assessmentDetailsWidgetStyles.AssessmentDetailsWidgetPointsInputWrapper>
      </assessmentDetailsWidgetStyles.AssessmentDetailsWidgetFormWrapper>

      <assessmentDetailsWidgetStyles.AssessmentDetailsWidgetInputLabel className="heading5 paddingBottom">Assessment Type</assessmentDetailsWidgetStyles.AssessmentDetailsWidgetInputLabel>

      <assessmentDetailsWidgetStyles.AssessmentDetailsRadioButtonsWrapper>
        <assessmentDetailsWidgetStyles.AssessmentDetailsWidgetGridLayout>
          <assessmentDetailsWidgetStyles.AssessmentDetailsWidgetGridItemLayout as="li">
            <FormControl
              paddingBottom={0}
              name="assessmentType"
              control={control}
              rules={{ required: 'Must select an assessment type' }}
              render={({ field, fieldState }) => (
                <RadioButtonCard
                  disabled={isDraft || !!assessmentSlug}
                  id="peerEndorsement"
                  {...field}
                  value="peerEndorsement"
                  checked={field.value === 'peerEndorsement'}
                  onChange={e => {
                    field.onChange(e.target.value);
                  }}
                >
                  <assessmentDetailsWidgetStyles.AssessmentDetailsRadioButtonCardWrapper className={classNames(`card`, { hasError: !!fieldState.error })}>
                    <assessmentDetailsWidgetStyles.AssessmentDetailsWidgetContentLayout className="toggleCardVerticalContentLayout">
                      <assessmentDetailsWidgetStyles.AssessmentDetailsWidgetRadioCardLabel className="toggleCardLabel" htmlFor="peerEndorsement">
                        <b>Peer Assessment</b>
                      </assessmentDetailsWidgetStyles.AssessmentDetailsWidgetRadioCardLabel>
                      <assessmentDetailsWidgetStyles.AssessmentDetailsWidgetSubtitle className="subtitle">Allow a group of people to endorse each other’s skills.</assessmentDetailsWidgetStyles.AssessmentDetailsWidgetSubtitle>
                    </assessmentDetailsWidgetStyles.AssessmentDetailsWidgetContentLayout>
                  </assessmentDetailsWidgetStyles.AssessmentDetailsRadioButtonCardWrapper>
                </RadioButtonCard>
              )}
            />
          </assessmentDetailsWidgetStyles.AssessmentDetailsWidgetGridItemLayout>

          <assessmentDetailsWidgetStyles.AssessmentDetailsWidgetGridItemLayout as="li">
            <FormControl
              paddingBottom={0}
              name="assessmentType"
              control={control}
              render={({ field, fieldState }) => (
                <RadioButtonCard
                  disabled={isDraft || !!assessmentSlug}
                  id="questionnaire"
                  {...field}
                  value="questionnaire"
                  checked={field.value === 'questionnaire'}
                  onChange={e => {
                    field.onChange(e.target.value);
                  }}
                >
                  <assessmentDetailsWidgetStyles.AssessmentDetailsRadioButtonCardWrapper className={classNames(`card`, { hasError: !!fieldState.error })}>
                    <assessmentDetailsWidgetStyles.AssessmentDetailsWidgetContentLayout className="toggleCardVerticalContentLayout">
                      <assessmentDetailsWidgetStyles.AssessmentDetailsWidgetRadioCardLabel className="toggleCardLabel" htmlFor="questionnaire">
                        <b>Questionnaire Based Assessment</b>
                      </assessmentDetailsWidgetStyles.AssessmentDetailsWidgetRadioCardLabel>
                      <assessmentDetailsWidgetStyles.AssessmentDetailsWidgetSubtitle className="subtitle">Answer scenario based questions in a multiple choice format.</assessmentDetailsWidgetStyles.AssessmentDetailsWidgetSubtitle>
                    </assessmentDetailsWidgetStyles.AssessmentDetailsWidgetContentLayout>
                  </assessmentDetailsWidgetStyles.AssessmentDetailsRadioButtonCardWrapper>
                </RadioButtonCard>
              )}
            />
          </assessmentDetailsWidgetStyles.AssessmentDetailsWidgetGridItemLayout>

          <assessmentDetailsWidgetStyles.AssessmentDetailsWidgetGridItemLayout as="li">
            <FormControl
              paddingBottom={0}
              name="assessmentType"
              control={control}
              render={({ field, fieldState }) => (
                <RadioButtonCard
                  disabled={isDraft || !!assessmentSlug}
                  id="fieldsOfPractice"
                  {...field}
                  value="fieldsOfPractice"
                  checked={field.value === 'fieldsOfPractice'}
                  onChange={e => {
                    field.onChange(e.target.value);
                  }}
                >
                  <assessmentDetailsWidgetStyles.AssessmentDetailsRadioButtonCardWrapper className={classNames(`card`, { hasError: !!fieldState.error })}>
                    <assessmentDetailsWidgetStyles.AssessmentDetailsWidgetContentLayout className="toggleCardVerticalContentLayout">
                      <assessmentDetailsWidgetStyles.AssessmentDetailsWidgetRadioCardLabel className="toggleCardLabel" htmlFor="fieldsOfPractice">
                        <b>Fields of Practice Assessment</b>
                      </assessmentDetailsWidgetStyles.AssessmentDetailsWidgetRadioCardLabel>

                      <assessmentDetailsWidgetStyles.AssessmentDetailsWidgetSubtitle className="subtitle">
                        Allows people to compare their skills against regulatory expectations. Ideal for job roles that have regulatory Continuous Professional Development (CPD) requirements.
                      </assessmentDetailsWidgetStyles.AssessmentDetailsWidgetSubtitle>
                    </assessmentDetailsWidgetStyles.AssessmentDetailsWidgetContentLayout>
                  </assessmentDetailsWidgetStyles.AssessmentDetailsRadioButtonCardWrapper>
                </RadioButtonCard>
              )}
            />
          </assessmentDetailsWidgetStyles.AssessmentDetailsWidgetGridItemLayout>

          {/* TODO: Reintroduce this when ready for Role Based Assessments */}
          {/* <assessmentDetailsWidgetStyles.AssessmentDetailsWidgetGridItemLayout as="li">
            <FormControl
              paddingBottom={0}
              name="assessmentType"
              control={control}
              render={({ field, fieldState }) => (
                <RadioButtonCard
                  disabled={isDraft || !!assessmentSlug}
                  id="roleBased"
                  {...field}
                  value="roleBased"
                  checked={field.value === 'roleBased'}
                  onChange={e => {
                    field.onChange(e.target.value);
                  }}
                >
                  <assessmentDetailsWidgetStyles.AssessmentDetailsRadioButtonCardWrapper className={classNames(`card`, { hasError: !!fieldState.error })}>
                    <assessmentDetailsWidgetStyles.AssessmentDetailsWidgetContentLayout className="toggleCardVerticalContentLayout">
                      <assessmentDetailsWidgetStyles.AssessmentDetailsWidgetRadioCardLabel className="toggleCardLabel" htmlFor="roleBased">
                        <b>Role-based Assessment</b>
                      </assessmentDetailsWidgetStyles.AssessmentDetailsWidgetRadioCardLabel>

                      <assessmentDetailsWidgetStyles.AssessmentDetailsWidgetSubtitle className="subtitle">
                        A method used to evaluate skills in specific job-related tasks or competencies through targeted evaluations and tests tailored to the requirements of a particular role or position.
                      </assessmentDetailsWidgetStyles.AssessmentDetailsWidgetSubtitle>
                    </assessmentDetailsWidgetStyles.AssessmentDetailsWidgetContentLayout>
                  </assessmentDetailsWidgetStyles.AssessmentDetailsRadioButtonCardWrapper>
                </RadioButtonCard>
              )}
            />
          </assessmentDetailsWidgetStyles.AssessmentDetailsWidgetGridItemLayout> */}
        </assessmentDetailsWidgetStyles.AssessmentDetailsWidgetGridLayout>
      </assessmentDetailsWidgetStyles.AssessmentDetailsRadioButtonsWrapper>

      <assessmentDetailsWidgetStyles.AssessmentDetailsSubmitButtonWrapper>
        {(nameEdited || !assessmentSlug) && (
          <assessmentDetailsWidgetStyles.AssessmentDetailsSubmitButton arrow onClick={handleSaveClick}>
            {isDraft ? 'Update' : 'Next'}
          </assessmentDetailsWidgetStyles.AssessmentDetailsSubmitButton>
        )}
      </assessmentDetailsWidgetStyles.AssessmentDetailsSubmitButtonWrapper>
    </form>
  );
}

