import React, { useEffect, useState } from 'react';
import { Assignee, TagAssigneeRequest, TagListItem } from '../../../../../models/tag';
import { TagType } from '../../../../../enums/tag';
import { useAppActions, useAppState } from '../../../../../overmind';
import { FetchType } from '../../../../../enums';
import { TagInput } from '../../../../../components/inputs/tags/tag-input';
import { useParams } from 'react-router-dom';
import { PagePath } from '../../../../../navigation/navigation.enums';
import { BaseSearchRequest } from '../../../../../models/overmind/search-request';
import { Anchor, Button } from '@keplerco/core';
import { EntitiesCardWidget } from '../../../../../components/cards/entities-card.widget';
import { useKeplerNavigate } from '../../../../../navigation/guards/use-kepler-navigate';
import styles from './activate-step.module.css';
import classNames from 'classnames';

interface IActivateStepProps {
  assessmentSlug: string;
  onComplete: () => void;
  onSaveDraft: () => void;
  goToPreviousStep: () => void;
}

export function ActivateStepLayout(props: IActivateStepProps): JSX.Element {
  const actions = useAppActions();
  const { companyVariables } = useAppState();
  const keplerNavigate = useKeplerNavigate();

  const [tags, setTags] = useState<TagListItem[] | undefined>([]);
  const [search, setSearch] = useState(``);
  const params = useParams();

  useEffect(() => {
    getTagAssignees();
  }, []);

  async function onClickRemoveTagHandler(tagName: string) {
    const assignees: TagAssigneeRequest[] = [
      {
        entitySlug: props.assessmentSlug,
        tagType: TagType.Assessment,
      },
    ];

    await actions.removeAssignees({ tagName, assignees, companySlug: companyVariables.slug! });
  }

  function getTagAssignees(): Assignee[] {
    return [
      {
        entitySlug: params.slug ?? props.assessmentSlug,
        tagType: TagType.Assessment,
      },
    ];
  }

  async function onClickCreateTagHandler(tagName: string, assignees: Assignee[], source: string) {
    if (source === 'input') {
      tagName = search;
      assignees = [
        {
          entitySlug: params.slug ?? props.assessmentSlug,
          tagType: TagType.Assessment,
        },
      ];
    }

    await actions.addAssignees({ tagName, assignees, companySlug: companyVariables.slug! });
  }

  function onTagChange(tags: TagListItem[]) {
    setTags(tags);
  }

  async function searchUserTags(query: string): Promise<TagListItem[]> {
    setSearch(query);
    const searchTagParams: BaseSearchRequest = {
      page: 1,
      pageSize: 15,
      search: query,
      sortAscending: true,
      sortField: 0,
      companySlug: companyVariables.slug!,
    };
    const paginatedResult = await actions.searchTags(searchTagParams);

    if (paginatedResult && paginatedResult.tags) {
      return paginatedResult.tags;
    }

    return [];
  }

  useEffect(() => {
    async function getData() {
      actions.startLoader({ path: PagePath.assessmentManagementAllAssessments, type: FetchType.DialogFetching });

      const result = await actions.getAssigneeTags({ entitySlug: params.slug ?? props.assessmentSlug, tagType: TagType.Assessment, companySlug: companyVariables.slug! });

      setTags(!!result ? result : []);

      actions.stopLoader(PagePath.assessmentManagementAllAssessments);
    }
    getData();
  }, []);

  return (
    <div className={styles.divider}>
      <EntitiesCardWidget title="Tag" description="Select tag for the assessment.">
        <TagInput entityLabel="assessment" onRemoveTag={onClickRemoveTagHandler} getAssignees={getTagAssignees} onCreateTag={onClickCreateTagHandler} initialTags={tags} onTagChange={onTagChange} searchTags={searchUserTags} />
      </EntitiesCardWidget>
      <footer className={classNames('card', styles.divider)} style={{ display: 'flex', alignItems: 'center', background: 'var(--background)' }}>
        <Anchor arrow reverse onClick={props.goToPreviousStep} style={{ marginRight: 'auto' }}>
          Back
        </Anchor>
        <div style={{ display: 'flex', gap: 15 }}>
          <Anchor onClick={props.onSaveDraft}>Save draft</Anchor>
          <Button
            type="button"
            filled
            onClick={async () => {
              actions.startLoader({ path: PagePath.assessmentManagementWizardFOP, type: FetchType.Sending });

              await actions.completeEndorsementSkillAssignment({ assessmentSlug: props.assessmentSlug });
              await actions.activateSkillAssessment({ companySlug: companyVariables.slug!, assessmentSlug: props.assessmentSlug });
              props.onComplete();
              keplerNavigate(`${PagePath.assessmentManagementBase}${PagePath.assessmentManagementAllAssessments.replace(':companySlug', companyVariables.slug!)}`);
              actions.stopLoader(PagePath.assessmentManagementWizardFOP);
            }}
          >
            Activate
          </Button>
        </div>
      </footer>
    </div>
  );
}

