import React, { useEffect, useReducer } from 'react';
import { AvatarIcon, GridItemLayout, GridLayout, Pager, Searchfield, colourString, useMatchScreenWidth } from '@keplerco/core';
import { FetchStatus, FetchType, OrganizationLevelType, SortField } from '../../../../../enums';
import { PagePath } from '../../../../../navigation/navigation.enums';
import { useAppActions, useAppState } from '../../../../../overmind';
import { IViewRolePeopleWidgetProps } from '../view-role.models';
import * as store from './view-role-people.store';
import styles from './view-role-people.module.css';
import { EmptyState } from '../../../../../components/general/empty-state/empty-state';
import { themedAssetUrl } from '../../../../../library/helpers/themed-asset-url';
import { extractHighestOrganizationLevel } from '../../../../../library/helpers/permissions/extract-highest-organization-level';
import { CompanyRoleAssigneesSearchParams } from '../../../../../models/overmind/search-params';
import { EntitiesCardWidget } from '../../../../../components/cards/entities-card.widget';

export default function ViewRolePeopleWidget({ path, role }: IViewRolePeopleWidgetProps) {
  const actions = useAppActions();
  const { companyVariables, fetchState, permissions } = useAppState();

  const [state, dispatch] = useReducer(store.reducer, store.initialState);

  const isMobile = useMatchScreenWidth('mobile');

  useEffect(() => {
    const organizationLevel = extractHighestOrganizationLevel(permissions?.roleManagement?.organizationLevels);
    const request: CompanyRoleAssigneesSearchParams = {
      search: undefined,
      sortAscending: true,
      sortField: SortField.Name,
      pageSize: 9,
      page: 1,
      organizationLevel: organizationLevel?.organizationLevel ?? OrganizationLevelType.Learner,
      companySlug: companyVariables.slug,
      departmentSlug: undefined,
      teamSlug: undefined,
      learnerSlug: undefined,
      roleSlug: role.slug,
    };
    dispatch({ type: store.ViewRolePeopleActionTypes.SetRequest, payload: request });
  }, [role]);

  async function updateData() {
    if (!state.request) return actions.stopLoader(path);
    const response = path !== PagePath.yourCareerRole
      ? await actions.getCompanyRoleAssignees(state.request)
      : await actions.getUserCompanyRoleAssignees(state.request);
    dispatch({ type: store.ViewRolePeopleActionTypes.SetData, payload: response });
    actions.stopLoader(path);
  }

  useEffect(() => {
    async function getData() {
      if (!state.request) return;
      actions.startLoader({ path, type: FetchType.PageFetching });
      const response = path !== PagePath.yourCareerRole
        ? await actions.getCompanyRoleAssignees(state.request)
        : await actions.getUserCompanyRoleAssignees(state.request);
      if (!!response?.assignees.length) dispatch({ type: store.ViewRolePeopleActionTypes.SetData, payload: response });
      actions.stopLoader(path);
    }

    !state.data ? getData() : updateData();
  }, [state.request]);

  return (
    <EntitiesCardWidget title="People" description="People assigned to the role">
      {!!state.data && (
        <Searchfield
          loading={fetchState[path].status === FetchStatus.Active && fetchState[path].type === FetchType.Custom}
          label="Search people"
          onInput={search => {
            if (!state.request) return;
            actions.startLoader({ path, type: FetchType.Custom });
            dispatch({ type: store.ViewRolePeopleActionTypes.SetRequest, payload: { ...state.request, search, page: 1 } });
          }}
          responsive
        />
      )}

      <div style={{ minHeight: 270, marginTop: 15, marginBottom: (!!state.data && state.data.totalPages > 1) ? 15 : 0 }}>
        {!!state.data?.assignees.length ? (
          <GridLayout columnCount={isMobile ? 1 : 3}>
            {state.data.assignees.map(person => (
              <GridItemLayout key={person.slug}>
                <div className={styles.personCard}>
                  <div className={styles.personCardIcon}>
                    <AvatarIcon name={{ firstName: person.name!.split(' ')[0], lastName: person.name!.split(' ')[1] }} />
                  </div>

                  <div className={styles.personCardContent}>
                    <p className="body1">{person.name}</p>
                    <span className="caption2" style={{ color: colourString('lilac') }}>{person.department}</span>
                  </div>
                </div>
              </GridItemLayout>
            ))}
          </GridLayout>
        ) : (
          <EmptyState
            badgeUrl={themedAssetUrl('graphics/empty-state-clouds.graphic.svg')}
            badgeIconWidth="full-width"
            subtitle="No people found"
            centerText
          />
        )}
      </div>

      {!!state.data && state.data?.totalPages > 1 && (
        <Pager
          activePageNumber={state.request?.page ?? 1}
          pageCount={state.data?.totalPages}
          onPageChange={page => {
            if (!state.request || page === state.request.page) return;
            actions.startLoader({ path, type: FetchType.Custom });
            dispatch({ type: store.ViewRolePeopleActionTypes.SetRequest, payload: { ...state.request, page } });
          }}
        />
      )}
    </EntitiesCardWidget>
  );
}

