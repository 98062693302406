import React from 'react';
import classNames from 'classnames';
import styles from './step-list.module.css';
import { StepListProps } from './step-list.models';
import CircleCheckIcon from '../../../../design/icons/circle-check.icon';

function StepList({ steps, currentStepIndex, allowedSteps, goToStep }: StepListProps) {
  return (
    <div className={styles.stepWrapper}>
      <div className={styles.stepList}>
        {steps.map(step => (
          <button
            key={step.stepNumber}
            className={classNames(styles.step, {
              [styles.active]: step.stepNumber === currentStepIndex,
              [styles.completed]: step.isCompleted,
            })}
            onClick={() => goToStep(step.stepNumber)}
            disabled={!allowedSteps.includes(step.stepNumber)}
          >
            {step.isCompleted && <CircleCheckIcon />}
            <span>{`${step.stepNumber}. ${step.label}`}</span>
          </button>
        ))}
      </div>
    </div>
  );
}

export default StepList;
