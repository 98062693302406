import React, { RefObject } from 'react';
import { ActivationStatus } from '../../../../enums';
import { useAppActions } from '../../../../overmind';
import { NotificationPriority, NotificationType } from '../../../../notifications/notifications.models';
import { IBulkActionsMenuProps } from './bulk-actions-menu.models';
import { Button, MenuList, MenuTriggerContainer, MenuItem, ChevronIcon } from '@keplerco/core';

export function BulkActionsMenu(props: IBulkActionsMenuProps): JSX.Element {
  const actions = useAppActions();

  function generateBulkActions(): MenuItem[] {
    const data = props.data;
    if (!data) return [];

    const menuItems: MenuItem[] = [
      // TODO
      // {
      //   text: 'Assign Tag',
      //   onClick: () => alert('Assign Tag'),
      // },
      // {
      //   text: 'Change Team',
      //   onClick: () => alert('Change Team'),
      // }
    ];

    const mappedSelection = data.employees.filter(employee => props.selectedRows.find(row => employee.id === row.id));

    const peopleToArchive = mappedSelection.filter(person => person.activationStatus !== ActivationStatus.Archived).map(person => person.id!);
    if (peopleToArchive.length > 0)
      menuItems.push({
        label: 'Archive Selected',
        onClick: async () => {
          props.handleBulkActionModalState({selectedPeople: peopleToArchive, showModal: true, actionType: 'archive'});
          props.setSelectedRows([]);
        },
      });

    const peopleToUnarchive = mappedSelection.filter(person => person.activationStatus === ActivationStatus.Archived).map(person => person.id!);
    if (peopleToUnarchive.length > 0)
      menuItems.push({
        label: 'Unarchive Selected',
        onClick: async () => {
          props.handleBulkActionModalState({selectedPeople: peopleToUnarchive, showModal: true, actionType: 'unarchive'});
          props.setSelectedRows([]);
        },
      });

    const peopleToInvite = mappedSelection.filter(person => person.activationStatus === ActivationStatus.NotInvited).map(person => person.email!);
    if (peopleToInvite.length > 0)
      menuItems.push({
        label: 'Send activation invitation(s)',
        onClick: () => props.onClickSendActivationInvitation({ emails: peopleToInvite, shouldClearRows: true }),
      });

    const peopleToRemind = mappedSelection.filter(person => person.activationStatus === ActivationStatus.Inactive).map(person => person.email!);
    if (peopleToRemind.length > 0)
      menuItems.push({
        label: 'Send activation reminder(s)',
        onClick: () => props.onClickSendActivationReminder({ emails: peopleToRemind, shouldClearRows: true }),
      });

    return menuItems;
  }

  return (
    <MenuList items={generateBulkActions()}>
      {({ triggerRef, onOpenHandler }) => (
        <MenuTriggerContainer
          ref={triggerRef as RefObject<HTMLDivElement>}
          onClick={() => {
            if (props.selectedRows.length > 0) {
              onOpenHandler();
            } else {
              actions.addNotification({
                title: 'No people selected',
                message: `Bulk actions require that you select at least one item in the list.`,
                active: true,
                type: NotificationType.Error,
                priority: NotificationPriority.Toast,
                id: `activation_error_${Date.now()}`,
              });
            }
          }}
        >
          <Button disabled={props.selectedRows.length < 1} type="button" square theme="dark">
            <div style={{ display: 'flex', gap: 15, alignItems: 'center' }}>
              Bulk Actions
              <ChevronIcon rotation={2} tone="primary" size={15} />
            </div>
          </Button>
        </MenuTriggerContainer>
      )}
    </MenuList>
  );
}

